import { useReactiveVar } from '@apollo/client';

import { Fragment, Suspense, useState } from 'react';
import { showSignUp } from 'store/Member';
import styled from 'styled-components';
import { retryLazy } from 'util/LazyUtil';

const SignIn = retryLazy(() => import('./SignIn/index'));
const SignUp = retryLazy(() => import('./SignUp/index'));
const ResetPasswordPopup = retryLazy(() => import('./SignIn/ResetPasswordPopup'));

const DarkBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
`;

const PopupWrapper = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
`;

const Member = () => {
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const isSignUp = useReactiveVar(showSignUp);

  return (
    <DarkBackground>
      <PopupWrapper>
        <Suspense fallback={<Fragment></Fragment>}>
          {isSignUp ? (
            <SignUp />
          ) : showResetPassword ? (
            <ResetPasswordPopup setShowResetPassword={setShowResetPassword} />
          ) : (
            <SignIn setShowResetPassword={setShowResetPassword} />
          )}
        </Suspense>
      </PopupWrapper>
    </DarkBackground>
  );
};

export default Member;
