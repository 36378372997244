import { useMemo } from 'react';
import Cookies from 'universal-cookie';

export default function useMemoCookies() {
  return useMemo(() => {
    const cookies = new Cookies();

    return cookies;
  }, []);
}
