import { navLarchiveIcon, navCustopiaIcon } from 'assets/image';

export interface MenuListType {
  id: number;
  name: string;
  title: string;
  url?: string;
  menuOpen: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: PathType[] | undefined;
}

export interface PathType {
  category: string;

  submenuList: {
    submenuTitle: string;
    submenuUrl: string;
    comingSoon: boolean;
  }[];
}

export interface QubeServiceListTypes {
  name: string;
  url?: string;
  icon: string;
}

export const MenuList: MenuListType[] = [
  {
    id: 0,
    name: 'logistic',
    title: '대시보드',
    url: '/logistic-tools/main',
    menuOpen: false,
    path: undefined,
  },
  {
    id: 1,
    name: 'plan',
    title: '가격정책',
    url: '/plan',
    menuOpen: false,
    path: undefined,
  },
  {
    id: 2,
    name: 'search-custpia',
    title: '관세검색',
    url: '/',
    menuOpen: false,
    path: undefined,
  },
  {
    id: 3,
    name: 'company-ad',
    title: '업체찾기',
    url: '/company-ad',
    menuOpen: false,
    path: undefined,
  },
  {
    id: 4,
    name: 'community',
    title: '커뮤니티',
    url: undefined,
    menuOpen: false,
    path: [
      {
        category: '',
        submenuList: [
          {
            submenuTitle: '뉴스센터',
            submenuUrl: '/community/news-center?page=1&hash=전체',

            comingSoon: false,
          },
          {
            submenuTitle: 'Q&A',
            submenuUrl: '/community/qna?page=1&hash=전체',

            comingSoon: false,
          },
          {
            submenuTitle: '자료실',
            submenuUrl: '/community/reference-room?page=1&hash=전체',

            comingSoon: false,
          },
          {
            submenuTitle: '자유게시판',
            submenuUrl: '/community/free?page=1',

            comingSoon: false,
          },
          {
            submenuTitle: '공지사항',
            submenuUrl: '/community/notice?page=1&hash=전체',

            comingSoon: false,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'warehouse_quote',
    title: '창고견적',
    url: '/warehouse_quote',
    menuOpen: false,
    path: undefined,
  },
  // {
  //   id: 6,
  //   name: 'faq',
  //   title: 'FAQ',
  //   url: '/faq',
  //   menuOpen: false,
  //   path: undefined,
  // },
];

export const SERVICE_LIST: { name: string; url: string }[] = [
  {
    name: '출석',
    url: '/mypage/attendance-check',
  },
  {
    name: '랭킹',
    url: '/ranking/fame',
  },
  {
    name: '게임',
    url: '/game',
  },
];

export const QUBE_SERVICE_LIST: QubeServiceListTypes[] = [
  {
    name: '라카이브',
    url: 'https://larchive.simplogis.com',
    icon: navLarchiveIcon,
  },
  {
    name: '커스트피아',
    url: process.env.REACT_APP_CUSTPIA_DOMAIN,
    icon: navCustopiaIcon,
  },
  // {
  //   name: '큐앤비',
  //   url: undefined,
  //   icon: navQnbIcon,
  // },
];

export const footerMenu: { [k: string]: string }[] = [
  {
    name: '개인정보처리방침',
    url: '/terms/privacy',
  },
  {
    name: '이용약관',
    url: '/terms/term',
  },
  // {
  //   name: '사이트맵',
  //   url: '/sitemap',
  // },
  {
    name: '제휴문의',
    url: '/partnership',
  },
  {
    name: '광고문의',
    url: '/ad-inquiry',
  },
];
