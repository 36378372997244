import { gql } from '@apollo/client';

export const LOGIN_CREATE_SIMPLOGIS_USER = gql`
  mutation Mut_Login_Old_Account_And_Create_Simplogis_User($input: LoginOldAccountAndCreateSimplogisUserInputDto!) {
    loginOldAccountAndCreateSimplogisUser(input: $input) {
      ok
      error {
        code
        message
      }
      customToken
    }
  }
`;
