import { LoadingSpinnerComponent } from 'assets/image';
import { getClass } from 'util/tailwind';

interface SpinnerProps {
  height?: string;
  isAbsolute?: boolean;
  spinnerWidth?: number | string;
  spinnerHeight?: number | string;
  isFixed?: boolean;
  informationText?: string | React.ReactNode;
}

export default function Spinner({
  height = 'min-h-[40vh]',
  isAbsolute = false,
  spinnerWidth = 60,
  spinnerHeight = 60,
  isFixed = false,
  informationText,
}: SpinnerProps) {
  return (
    <div
      className={getClass(
        `flex flex-col justify-center items-center  ${height}`,
        isAbsolute ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-transparent z-50' : '',
        isFixed ? 'fixed top-0 left-0 w-full h-full z-100 bg-[rgba(0,0,0,0.6)]' : '',
      )}
    >
      <LoadingSpinnerComponent width={spinnerWidth} height={spinnerHeight} />
      {informationText && <div className="text-[26px] font-bold text-white mt-5">{informationText}</div>}
    </div>
  );
}
