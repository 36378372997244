import styled from 'styled-components';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

interface BtnBg {
  btnBg?: boolean;
}

const ButtonContainer = styled.button<BtnBg>`
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, btnBg }) => (btnBg ? theme.colors.baseColor : theme.colors.buttonBackgroundColor)};
  color: ${props => (props.btnBg ? '#fff' : props.theme.colors.buttontextNavyColor)};
  border-radius: 6px;
  cursor: pointer;
`;

interface ButtonProps {
  type: 'button' | 'submit';
  text: string;
  btnBg?: boolean;
  onClick?: () => void;
  [key: string]: any;
}

export default function Button({ text, btnBg = false, onClick, type = 'button', ...rest }: ButtonProps) {
  return (
    <ButtonWrapper>
      {type === 'button' && (
        <ButtonContainer btnBg={btnBg} {...rest} type={type} onClick={onClick}>
          {text}
        </ButtonContainer>
      )}
      {type === 'submit' && <ButtonContainer type={type}>{text}</ButtonContainer>}
    </ButtonWrapper>
  );
}
