import {
  Que_Get_Profile_showProfile_user,
  Que_Get_Profile_showProfile_user_subscription,
} from '__apollo__generated__/Que_Get_Profile';
import { atom, selector } from 'recoil';
import uid from 'tiny-uid';

export const userProfileAtom = atom<Que_Get_Profile_showProfile_user | null>({
  key: `user-Profile-Atom/${uid()}`,
  default: null,
});

export const userPlanSelector = selector<Que_Get_Profile_showProfile_user_subscription | null>({
  key: `user-Plan-Selector/${uid()}`,
  get({ get }): Que_Get_Profile_showProfile_user_subscription | null {
    const userProfile = get(userProfileAtom);

    return userProfile?.subscription || null;
  },
});
