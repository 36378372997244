/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ACTIVATE_DORMANT_ACCOUNT_ERR_CODE {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum AdType {
  L1 = "L1",
  L10 = "L10",
  L11 = "L11",
  L12 = "L12",
  L13 = "L13",
  L14 = "L14",
  L15 = "L15",
  L2 = "L2",
  L3 = "L3",
  L4 = "L4",
  L5 = "L5",
  L6 = "L6",
  L7 = "L7",
  L8 = "L8",
  L9 = "L9",
  PREMIUM_BANNER = "PREMIUM_BANNER",
}

export enum AdminRole {
  ETC = "ETC",
  POST_CRUD = "POST_CRUD",
  TAG_CRUD = "TAG_CRUD",
  USER_CRUD = "USER_CRUD",
}

export enum AuthList {
  AEO = "AEO",
  CELC_CargoInformationNetworkCompany = "CELC_CargoInformationNetworkCompany",
  CELC_ComprehensiveLogisticsServiceCompany = "CELC_ComprehensiveLogisticsServiceCompany",
  CELC_FreightCarTransportationCompany = "CELC_FreightCarTransportationCompany",
  CELC_InternationalLogisticsBrokerageCompany = "CELC_InternationalLogisticsBrokerageCompany",
  CELC_LogisticsWarehouseCompany = "CELC_LogisticsWarehouseCompany",
  CESS = "CESS",
  CGLC = "CGLC",
  C_TPAT = "C_TPAT",
  FIATA = "FIATA",
  FMC = "FMC",
  IATA = "IATA",
  ISO_9001 = "ISO_9001",
  KIFFA = "KIFFA",
}

export enum BI25BLACKROCK {
  CHIN = "CHIN",
  CLIM = "CLIM",
  COMP = "COMP",
  CYBR = "CYBR",
  EMER = "EMER",
  EURO = "EURO",
  GULF = "GULF",
  KORE = "KORE",
  TECH = "TECH",
  TERR = "TERR",
  UKRN = "UKRN",
}

export enum Bi01 {
  AUSTRALIA_NEW_ZEALAND = "AUSTRALIA_NEW_ZEALAND",
  COMPOSITEINDEX = "COMPOSITEINDEX",
  EAST_WEST_AFRICA = "EAST_WEST_AFRICA",
  EUROPE = "EUROPE",
  JAPAN = "JAPAN",
  KOREA = "KOREA",
  MEDITERRANEAN = "MEDITERRANEAN",
  PERSIAN_GULF_RED_SEA_SERVICE = "PERSIAN_GULF_RED_SEA_SERVICE",
  SOUTHEAST_ASIA = "SOUTHEAST_ASIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_AMERICA = "SOUTH_AMERICA",
  USEC = "USEC",
  USWC = "USWC",
}

export enum Bi02 {
  AUSTRALIA_NEW_ZEALAND = "AUSTRALIA_NEW_ZEALAND",
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  DATES = "DATES",
  EAST_JAPAN = "EAST_JAPAN",
  EAST_WEST_AFRICA = "EAST_WEST_AFRICA",
  EUROPE = "EUROPE",
  KOREA = "KOREA",
  MEDITERRANEAN = "MEDITERRANEAN",
  MIDDLE_EAST = "MIDDLE_EAST",
  SOUTHEAST_ASIA = "SOUTHEAST_ASIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_AMERICA = "SOUTH_AMERICA",
  USEC = "USEC",
  USWC = "USWC",
  WEST_JAPAN = "WEST_JAPAN",
}

export enum Bi03 {
  AUSTRALIA_NEW_ZEALAND = "AUSTRALIA_NEW_ZEALAND",
  BLACK_SEA = "BLACK_SEA",
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  EUROPE = "EUROPE",
  E_AFRICA = "E_AFRICA",
  E_AMERICA = "E_AMERICA",
  E_JAPAN = "E_JAPAN",
  E_MEDITERRANEAN = "E_MEDITERRANEAN",
  E_SOUTH_AMERICA = "E_SOUTH_AMERICA",
  INDIA_PAKISTAN = "INDIA_PAKISTAN",
  MIDDLE_EAST = "MIDDLE_EAST",
  N_AFRICA = "N_AFRICA",
  PHILIPPINES = "PHILIPPINES",
  RED_SEA = "RED_SEA",
  SINGAPORE_MALAYSIA = "SINGAPORE_MALAYSIA",
  S_AFRICA = "S_AFRICA",
  THAILAND_VIETNAM = "THAILAND_VIETNAM",
  W_AFRICA = "W_AFRICA",
  W_AMERICA = "W_AMERICA",
  W_JAPAN = "W_JAPAN",
  W_MEDITERRANEAN = "W_MEDITERRANEAN",
  W_SOUTH_AMERICA = "W_SOUTH_AMERICA",
}

export enum Bi04 {
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  LOS_ANGELES_SHANGHAI = "LOS_ANGELES_SHANGHAI",
  NEW_YORK_ROTTERDAM = "NEW_YORK_ROTTERDAM",
  ROTTERDAM_NEW_YORK = "ROTTERDAM_NEW_YORK",
  ROTTERDAM_SHANGHAI = "ROTTERDAM_SHANGHAI",
  SHANGHAI_GENOVA = "SHANGHAI_GENOVA",
  SHANGHAI_LOS_ANGELES = "SHANGHAI_LOS_ANGELES",
  SHANGHAI_NEW_YORK = "SHANGHAI_NEW_YORK",
  SHANGHAI_ROTTERDAM = "SHANGHAI_ROTTERDAM",
}

export enum Bi05 {
  CHINA_EAST_ASIA_MEDITERRANEAN = "CHINA_EAST_ASIA_MEDITERRANEAN",
  CHINA_EAST_ASIA_NORTH_AMERICA_EAST_COAST = "CHINA_EAST_ASIA_NORTH_AMERICA_EAST_COAST",
  CHINA_EAST_ASIA_NORTH_AMERICA_WEST_COAST = "CHINA_EAST_ASIA_NORTH_AMERICA_WEST_COAST",
  CHINA_EAST_ASIA_NORTH_EUROPE = "CHINA_EAST_ASIA_NORTH_EUROPE",
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  EUROPE_SOUTH_AMERICA_EAST_COAST = "EUROPE_SOUTH_AMERICA_EAST_COAST",
  EUROPE_SOUTH_AMERICA_WEST_COAST = "EUROPE_SOUTH_AMERICA_WEST_COAST",
  MEDITERRANEAN_CHINA_EAST_ASIA = "MEDITERRANEAN_CHINA_EAST_ASIA",
  NORTH_AMERICA_EAST_COAST_CHINA_EAST_ASIA = "NORTH_AMERICA_EAST_COAST_CHINA_EAST_ASIA",
  NORTH_AMERICA_EAST_COAST_NORTH_EUROPE = "NORTH_AMERICA_EAST_COAST_NORTH_EUROPE",
  NORTH_AMERICA_WEST_COST_CHINA_EAST_ASIA = "NORTH_AMERICA_WEST_COST_CHINA_EAST_ASIA",
  NORTH_EUROPE_CHINA_EAST_ASIA = "NORTH_EUROPE_CHINA_EAST_ASIA",
  NORTH_EUROPE_NORTH_AMERICA_EAST_COAST = "NORTH_EUROPE_NORTH_AMERICA_EAST_COAST",
}

export enum Bi06 {
  Far_East = "Far_East",
  N_Europe = "N_Europe",
  SAEC = "SAEC",
  USEC = "USEC",
  USWC = "USWC",
}

export enum Bi07 {
  AUSTRAILIA = "AUSTRAILIA",
  CHINA = "CHINA",
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  EUROPE = "EUROPE",
  JAPN = "JAPN",
  LAEC = "LAEC",
  LAWC = "LAWC",
  MEDITERRANEAN = "MEDITERRANEAN",
  MIDDLE_EAST = "MIDDLE_EAST",
  SAFRICA = "SAFRICA",
  SOUTH_EAST_ASIA = "SOUTH_EAST_ASIA",
  USEC = "USEC",
  USWC = "USWC",
  WAFRICA = "WAFRICA",
}

export enum Bi08 {
  CHINA = "CHINA",
  DATES = "DATES",
  EUROPE = "EUROPE",
  EXPORTS = "EXPORTS",
  IMPORTS = "IMPORTS",
  JAPAN = "JAPAN",
  USEC = "USEC",
  USWC = "USWC",
  VIETNAM = "VIETNAM",
}

export enum Bi09 {
  INDEX = "INDEX",
}

export enum Bi11 {
  ANTWERP = "ANTWERP",
  CHICAGO = "CHICAGO",
  DC20 = "DC20",
  DC40 = "DC40",
  DURBAN = "DURBAN",
  GENOA = "GENOA",
  HAMBURG = "HAMBURG",
  ISTANBUL = "ISTANBUL",
  LOS_ANGELES = "LOS_ANGELES",
  NHAVA_SHEVA = "NHAVA_SHEVA",
  QINGDAO = "QINGDAO",
  SHANGHAI = "SHANGHAI",
  SINGAPORE = "SINGAPORE",
}

export enum Bi12BF {
  CAPESIZE = "CAPESIZE",
  HANDYSIZE = "HANDYSIZE",
  PANAMAX = "PANAMAX",
  SUPRAMAX = "SUPRAMAX",
}

export enum Bi12BFC {
  CAPESIZE = "CAPESIZE",
  HANDYMAX = "HANDYMAX",
  PANAMAX = "PANAMAX",
  SUPRAMAX = "SUPRAMAX",
}

export enum Bi12BFI {
  BCI = "BCI",
  BDI = "BDI",
  BHSI = "BHSI",
  BPI = "BPI",
  BSI = "BSI",
}

export enum Bi13 {
  AVERAGE_EARNINGS = "AVERAGE_EARNINGS",
  MR = "MR",
  TANKER_DAILY_CHARTERAGE = "TANKER_DAILY_CHARTERAGE",
  TANKER_WORLD_SCALE = "TANKER_WORLD_SCALE",
  VLCC = "VLCC",
}

export enum Bi14IFO {
  FUJAIRAH = "FUJAIRAH",
  GIBRALTAR = "GIBRALTAR",
  GLOBAL_20 = "GLOBAL_20",
  HONG_KONG = "HONG_KONG",
  HOUSTON = "HOUSTON",
  ISTANBUL = "ISTANBUL",
  KOREA = "KOREA",
  LA_LONG_BEACH = "LA_LONG_BEACH",
  NEWYORK = "NEWYORK",
  PIRAEUS = "PIRAEUS",
  ROTTERDAM = "ROTTERDAM",
  SINGAPORE = "SINGAPORE",
}

export enum Bi14MGO {
  FUJAIRAH = "FUJAIRAH",
  GLOBAL_20 = "GLOBAL_20",
  HONG_KONG = "HONG_KONG",
  HOUSTON = "HOUSTON",
  ISTANBUL = "ISTANBUL",
  LA_LONG_BEACH = "LA_LONG_BEACH",
  NEWYORK = "NEWYORK",
  PIRAEUS = "PIRAEUS",
  ROTTERDAM = "ROTTERDAM",
  SANTOS = "SANTOS",
  SINGAPORE = "SINGAPORE",
}

export enum Bi14VLSFO {
  FUJAIRAH = "FUJAIRAH",
  GIBRALTAR = "GIBRALTAR",
  GLOBAL_20 = "GLOBAL_20",
  HONG_KONG = "HONG_KONG",
  HOUSTON = "HOUSTON",
  ISTANBUL = "ISTANBUL",
  LA_LONG_BEACH = "LA_LONG_BEACH",
  NEWYORK = "NEWYORK",
  PIRAEUS = "PIRAEUS",
  ROTTERDAM = "ROTTERDAM",
  SANTOS = "SANTOS",
  SINGAPORE = "SINGAPORE",
}

export enum Bi15 {
  BRENT = "BRENT",
  DUBAI = "DUBAI",
  WTI = "WTI",
}

export enum Bi16NSB {
  CAP180K = "CAP180K",
  HANDY33K = "HANDY33K",
  KAMSARMAX82K = "KAMSARMAX82K",
  ULTRAMAX64K = "ULTRAMAX64K",
}

export enum Bi16NST {
  AFRAMAX110K = "AFRAMAX110K",
  LR174K = "LR174K",
  LR2110K = "LR2110K",
  MR50K = "MR50K",
  SUEZMAX160K = "SUEZMAX160K",
  VLCC320K = "VLCC320K",
}

export enum Bi16USB {
  CAPE180K_10Y = "CAPE180K_10Y",
  CAPE180K_5Y = "CAPE180K_5Y",
  CAPE180K_RESALE = "CAPE180K_RESALE",
  HANDY33K_10Y = "HANDY33K_10Y",
  HANDY33K_5Y = "HANDY33K_5Y",
  HANDY33K_RESALE = "HANDY33K_RESALE",
  KAMSARMAX82K_10Y = "KAMSARMAX82K_10Y",
  KAMSARMAX82K_5Y = "KAMSARMAX82K_5Y",
  KAMSARMAX82K_RESALE = "KAMSARMAX82K_RESALE",
  ULTRAMAX64K_10Y = "ULTRAMAX64K_10Y",
  ULTRAMAX64K_5Y = "ULTRAMAX64K_5Y",
  ULTRAMAX64K_RESALE = "ULTRAMAX64K_RESALE",
}

export enum Bi16UST {
  AFRAMAX110K_10Y = "AFRAMAX110K_10Y",
  AFRAMAX110K_5Y = "AFRAMAX110K_5Y",
  AFRAMAX110K_RESALE = "AFRAMAX110K_RESALE",
  LR175K_10Y = "LR175K_10Y",
  LR175K_5Y = "LR175K_5Y",
  LR175K_RESALE = "LR175K_RESALE",
  LR2110K_10Y = "LR2110K_10Y",
  LR2110K_5Y = "LR2110K_5Y",
  LR2110K_RESALE = "LR2110K_RESALE",
  MR50K_10Y = "MR50K_10Y",
  MR50K_5Y = "MR50K_5Y",
  MR50K_RESALE = "MR50K_RESALE",
  SUEZMAX160K_10Y = "SUEZMAX160K_10Y",
  SUEZMAX160K_5Y = "SUEZMAX160K_5Y",
  SUEZMAX160K_RESALE = "SUEZMAX160K_RESALE",
  VLCC320K_10Y = "VLCC320K_10Y",
  VLCC320K_5Y = "VLCC320K_5Y",
  VLCC320K_RESALE = "VLCC320K_RESALE",
}

export enum Bi17 {
  FRAUSA = "FRAUSA",
  HKGEUR = "HKGEUR",
  HKGUSA = "HKGUSA",
}

export enum Bi18 {
  RATE = "RATE",
}

export enum Bi24IDC {
  Africa = "Africa",
  Asia_Pacific = "Asia_Pacific",
  CAPACITY_GROWTH = "CAPACITY_GROWTH",
  DEMAND_GROWTH = "DEMAND_GROWTH",
  Europe = "Europe",
  Latin_America = "Latin_America",
  Middle_East = "Middle_East",
  North_America = "North_America",
  SCORE = "SCORE",
  Total_Market = "Total_Market",
}

export enum Bi24IF {
  AFRICA_LF = "AFRICA_LF",
  ASIAPACIFIC_LF = "ASIAPACIFIC_LF",
  EUROPE_LF = "EUROPE_LF",
  LATINAMERICA_LF = "LATINAMERICA_LF",
  MIDDLEEAST_LF = "MIDDLEEAST_LF",
  NORTHAMERICA_LF = "NORTHAMERICA_LF",
  TOTALMARKET_LF = "TOTALMARKET_LF",
}

export enum Bi25BUSAN {
  EXPORTS = "EXPORTS",
  IMPORTS = "IMPORTS",
  ORIGIN_DEST_BUSAN = "ORIGIN_DEST_BUSAN",
  ORIGIN_DEST_DUBAI = "ORIGIN_DEST_DUBAI",
  ORIGIN_DEST_HAMBURG = "ORIGIN_DEST_HAMBURG",
  ORIGIN_DEST_HO_CHI_MINH = "ORIGIN_DEST_HO_CHI_MINH",
  ORIGIN_DEST_LOS_ANGELES = "ORIGIN_DEST_LOS_ANGELES",
  ORIGIN_DEST_MANZANILLO = "ORIGIN_DEST_MANZANILLO",
  ORIGIN_DEST_MONTREAL = "ORIGIN_DEST_MONTREAL",
  ORIGIN_DEST_MUMBAI = "ORIGIN_DEST_MUMBAI",
  ORIGIN_DEST_NEW_YORK = "ORIGIN_DEST_NEW_YORK",
  ORIGIN_DEST_ROTTERDAM = "ORIGIN_DEST_ROTTERDAM",
  ORIGIN_DEST_SAINT_PETERSBURG = "ORIGIN_DEST_SAINT_PETERSBURG",
  ORIGIN_DEST_SHANGHAI = "ORIGIN_DEST_SHANGHAI",
  ORIGIN_DEST_SINGAPORE = "ORIGIN_DEST_SINGAPORE",
  ORIGIN_DEST_TOKYO = "ORIGIN_DEST_TOKYO",
  ORIGIN_DEST_VANCOUVER = "ORIGIN_DEST_VANCOUVER",
  ORIGIN_DEST_VOSTOCHNY = "ORIGIN_DEST_VOSTOCHNY",
  ORIGIN_DEST_XINGANG = "ORIGIN_DEST_XINGANG",
  ORIGIN_DEST_YOKOHAMA = "ORIGIN_DEST_YOKOHAMA",
  RATE = "RATE",
}

export enum Bi25ICH {
  DEST_INCHEON = "DEST_INCHEON",
  EXPORTS = "EXPORTS",
  IMPORTS = "IMPORTS",
  KG100 = "KG100",
  KG1000 = "KG1000",
  KG300 = "KG300",
  KG45 = "KG45",
  KG500 = "KG500",
  ORIGIN_DEST_ATLANTA_ATL = "ORIGIN_DEST_ATLANTA_ATL",
  ORIGIN_DEST_BEIJING_PEK = "ORIGIN_DEST_BEIJING_PEK",
  ORIGIN_DEST_CHICAGO_ORD = "ORIGIN_DEST_CHICAGO_ORD",
  ORIGIN_DEST_FRANKFRUT_FRA = "ORIGIN_DEST_FRANKFRUT_FRA",
  ORIGIN_DEST_HANGZHOU_HGH = "ORIGIN_DEST_HANGZHOU_HGH",
  ORIGIN_DEST_HONG_KONG_HKG = "ORIGIN_DEST_HONG_KONG_HKG",
  ORIGIN_DEST_HO_CHI_MINH_SGN = "ORIGIN_DEST_HO_CHI_MINH_SGN",
  ORIGIN_DEST_KANSAI_KIX = "ORIGIN_DEST_KANSAI_KIX",
  ORIGIN_DEST_KUALA_LUMPUR_KUL = "ORIGIN_DEST_KUALA_LUMPUR_KUL",
  ORIGIN_DEST_LOS_ANGELES_LAX = "ORIGIN_DEST_LOS_ANGELES_LAX",
  ORIGIN_DEST_NAGOYA_NGO = "ORIGIN_DEST_NAGOYA_NGO",
  ORIGIN_DEST_NARITA_NRT = "ORIGIN_DEST_NARITA_NRT",
  ORIGIN_DEST_NEW_YORK_JFK = "ORIGIN_DEST_NEW_YORK_JFK",
  ORIGIN_DEST_PENANG_PEN = "ORIGIN_DEST_PENANG_PEN",
  ORIGIN_DEST_PUDONG_PVG = "ORIGIN_DEST_PUDONG_PVG",
  ORIGIN_DEST_QINGDAO_TAO = "ORIGIN_DEST_QINGDAO_TAO",
  ORIGIN_DEST_SAN_FRANCISCO_SFO = "ORIGIN_DEST_SAN_FRANCISCO_SFO",
  ORIGIN_DEST_SINGAPORE_SIN = "ORIGIN_DEST_SINGAPORE_SIN",
  ORIGIN_DEST_TIANJIN_TSN = "ORIGIN_DEST_TIANJIN_TSN",
  ORIGIN_INCHON_ICN = "ORIGIN_INCHON_ICN",
}

export enum Bi26 {
  Afghanistan = "Afghanistan",
  Algeria = "Algeria",
  Angola = "Angola",
  Argentina = "Argentina",
  Armenia = "Armenia",
  Australi = "Australi",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Bahamas_The = "Bahamas_The",
  Bangladesh = "Bangladesh",
  Belarus = "Belarus",
  Belgium = "Belgium",
  Belize = "Belize",
  Bolivia = "Bolivia",
  Botswana = "Botswana",
  Brazil = "Brazil",
  Brunei = "Brunei",
  Bulgaria = "Bulgaria",
  Burkina_Faso = "Burkina_Faso",
  Cambodia = "Cambodia",
  Cameroon = "Cameroon",
  Canada = "Canada",
  Central_African_Republic = "Central_African_Republic",
  Chad = "Chad",
  Chile = "Chile",
  China = "China",
  Colombia = "Colombia",
  Congo = "Congo",
  Costa_Rica = "Costa_Rica",
  Cote_D_Ivoire_Ivory_Coast = "Cote_D_Ivoire_Ivory_Coast",
  Croatia_Hrvatska = "Croatia_Hrvatska",
  Cuba = "Cuba",
  Cyprus = "Cyprus",
  Czech_Republic = "Czech_Republic",
  Denmark = "Denmark",
  Dominican_Republic = "Dominican_Republic",
  Ecuador = "Ecuador",
  Egypt = "Egypt",
  El_Salvador = "El_Salvador",
  Estonia = "Estonia",
  Ethiopia = "Ethiopia",
  Faroe_Islands = "Faroe_Islands",
  Fiji = "Fiji",
  Finland = "Finland",
  France = "France",
  Gabon = "Gabon",
  Georgia = "Georgia",
  Germany = "Germany",
  Ghana = "Ghana",
  Greece = "Greece",
  Guatemala = "Guatemala",
  Guinea = "Guinea",
  Guyana = "Guyana",
  Haiti = "Haiti",
  Honduras = "Honduras",
  Hong_Kong = "Hong_Kong",
  Hungary = "Hungary",
  Iceland = "Iceland",
  India = "India",
  Indonesia = "Indonesia",
  Iran = "Iran",
  Iraq = "Iraq",
  Ireland = "Ireland",
  Israel = "Israel",
  Italy = "Italy",
  Jamaica = "Jamaica",
  Japan = "Japan",
  Jet_A1_price_MT = "Jet_A1_price_MT",
  Jet_A1_price_bbl = "Jet_A1_price_bbl",
  Jet_A1_price_gallon = "Jet_A1_price_gallon",
  Jet_A1_price_liter = "Jet_A1_price_liter",
  Jordan = "Jordan",
  Kazakhstan = "Kazakhstan",
  Kenya = "Kenya",
  Kiribati = "Kiribati",
  Korea_North = "Korea_North",
  Korea_South = "Korea_South",
  Kuwait = "Kuwait",
  Kyrgyzstan = "Kyrgyzstan",
  Laos = "Laos",
  Latvia = "Latvia",
  Lesotho = "Lesotho",
  Libya = "Libya",
  Lithuania = "Lithuania",
  Madagascar = "Madagascar",
  Malawi = "Malawi",
  Malaysia = "Malaysia",
  Maldives = "Maldives",
  Mali = "Mali",
  Marshall_Islands = "Marshall_Islands",
  Mexico = "Mexico",
  Moldova = "Moldova",
  Mongolia = "Mongolia",
  Morocco = "Morocco",
  Mozambik = "Mozambik",
  Myanmar = "Myanmar",
  Namibia = "Namibia",
  Nepal = "Nepal",
  Netherlands = "Netherlands",
  New_Zealand = "New_Zealand",
  Nicaragua = "Nicaragua",
  Niger = "Niger",
  Nigeria = "Nigeria",
  Norway = "Norway",
  Oman = "Oman",
  Pakistan = "Pakistan",
  Panama = "Panama",
  Papua_New_Guinea = "Papua_New_Guinea",
  Paraguay = "Paraguay",
  Peru = "Peru",
  Philippines = "Philippines",
  Poland = "Poland",
  Portugal = "Portugal",
  Qatar = "Qatar",
  Romania = "Romania",
  Russia = "Russia",
  Saudi_Arabia = "Saudi_Arabia",
  Senegal = "Senegal",
  Serbia = "Serbia",
  Seychelles = "Seychelles",
  Singapore = "Singapore",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  Solomon_Islands = "Solomon_Islands",
  Somalia = "Somalia",
  South_Africa = "South_Africa",
  South_Sudan = "South_Sudan",
  Spain = "Spain",
  Sri_Lanka = "Sri_Lanka",
  Sudan = "Sudan",
  Suriname = "Suriname",
  Swaziland = "Swaziland",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  Syria = "Syria",
  Taiwan = "Taiwan",
  Tajikistan = "Tajikistan",
  Tanzania = "Tanzania",
  Thailand = "Thailand",
  Togo = "Togo",
  Tunisia = "Tunisia",
  Turkey = "Turkey",
  Turkmenistan = "Turkmenistan",
  Uganda = "Uganda",
  Ukraine = "Ukraine",
  United_Arab_Emirates = "United_Arab_Emirates",
  United_Kingdom = "United_Kingdom",
  United_States = "United_States",
  Uruguay = "Uruguay",
  Uzbekistan = "Uzbekistan",
  Vanuatu = "Vanuatu",
  Venezuela = "Venezuela",
  Vietnam = "Vietnam",
  Yemen = "Yemen",
  Zambia = "Zambia",
  Zimbabwe = "Zimbabwe",
  liberia = "liberia",
  mauretania = "mauretania",
}

export enum Bi36 {
  AVG = "AVG",
  EIGHTFIVE = "EIGHTFIVE",
  FOURTWOFIE = "FOURTWOFIE",
  INDEX = "INDEX",
  MIN = "MIN",
  ONEONE = "ONEONE",
  ONESEVEN = "ONESEVEN",
  SEVEN = "SEVEN",
  SIXFIVE = "SIXFIVE",
  THREEFIVE = "THREEFIVE",
  TWOFIVE = "TWOFIVE",
  TWOSEVEN = "TWOSEVEN",
  max = "max",
}

export enum Bi37 {
  VALUE = "VALUE",
}

export enum Bi40CURRENT {
  DOMESTIC_SEATS = "DOMESTIC_SEATS",
  GLOBAL_TOTAL_SEATS = "GLOBAL_TOTAL_SEATS",
  INTERNATIONAL_SEATS = "INTERNATIONAL_SEATS",
}

export enum Bi40SCHEDULE {
  DOMESTIC_SEATS = "DOMESTIC_SEATS",
  GLOBAL_TOTAL_SEATS = "GLOBAL_TOTAL_SEATS",
  INTERNATIONAL_SEATS = "INTERNATIONAL_SEATS",
}

export enum Bi41 {
  MONTHLY_CHANGE = "MONTHLY_CHANGE",
  MONTHLY_INDEX = "MONTHLY_INDEX",
  MONTHLY_PATTERN = "MONTHLY_PATTERN",
}

export enum Bi43 {
  CHINA = "CHINA",
  EU = "EU",
  JAPAN = "JAPAN",
  USA = "USA",
  VIETNAM = "VIETNAM",
}

export enum Bi48 {
  COMPOSITE_INDEX = "COMPOSITE_INDEX",
  EAST_AFRICA = "EAST_AFRICA",
  EAST_COAST_NORTH_AMERICA = "EAST_COAST_NORTH_AMERICA",
  EAST_COAST_SOUTH_AMERICA = "EAST_COAST_SOUTH_AMERICA",
  EAST_US = "EAST_US",
  LATIN_AMERICA = "LATIN_AMERICA",
  MEDITERRANEAN = "MEDITERRANEAN",
  MIDDLE_EAST = "MIDDLE_EAST",
  NORTH_EAST_ASIA = "NORTH_EAST_ASIA",
  NORTH_EUROPE = "NORTH_EUROPE",
  OCEANIA = "OCEANIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_ASIA = "SOUTH_ASIA",
  SOUTH_EAST_ASIA = "SOUTH_EAST_ASIA",
  WEST_AFRICA = "WEST_AFRICA",
  WEST_COAST_NORTH_AMERICA = "WEST_COAST_NORTH_AMERICA",
  WEST_COAST_SOUTH_AMERICA = "WEST_COAST_SOUTH_AMERICA",
  WEST_US = "WEST_US",
}

export enum Bi50Category {
  CHINA_LOS = "CHINA_LOS",
  CHINA_NY_NJ = "CHINA_NY_NJ",
}

export enum Bi53Route {
  COMPOSITE = "COMPOSITE",
  TIANJIN_CENTRAL_AND_SOUTH_AMERICA_BASE_PORT = "TIANJIN_CENTRAL_AND_SOUTH_AMERICA_BASE_PORT",
  TIANJIN_EASTERN_OF_MEDITERRANEAN_BASE_PORT = "TIANJIN_EASTERN_OF_MEDITERRANEAN_BASE_PORT",
  TIANJIN_EAST_AFRICA_BASE_PORT = "TIANJIN_EAST_AFRICA_BASE_PORT",
  TIANJIN_EAST_COAST_OF_AMERICA_BASE_PORT = "TIANJIN_EAST_COAST_OF_AMERICA_BASE_PORT",
  TIANJIN_EAST_COAST_OF_JAPAN_BASE_PORT = "TIANJIN_EAST_COAST_OF_JAPAN_BASE_PORT",
  TIANJIN_EAST_COAST_OF_SOUTH_AMERICA_BASE_PORT = "TIANJIN_EAST_COAST_OF_SOUTH_AMERICA_BASE_PORT",
  TIANJIN_EUROPE_BASE_POR = "TIANJIN_EUROPE_BASE_POR",
  TIANJIN_INDIA_AND_PAKISTAN_BASE_PORT = "TIANJIN_INDIA_AND_PAKISTAN_BASE_PORT",
  TIANJIN_KOREA_INCHON = "TIANJIN_KOREA_INCHON",
  TIANJIN_KOREA_PUSAN = "TIANJIN_KOREA_PUSAN",
  TIANJIN_SOUTHEAST_ASIA_HOCHIMINH = "TIANJIN_SOUTHEAST_ASIA_HOCHIMINH",
  TIANJIN_SOUTHEAST_ASIA_PORT_KELANG = "TIANJIN_SOUTHEAST_ASIA_PORT_KELANG",
  TIANJIN_WESTERN_OF_MEDITERRANEAN_BASE_PORT = "TIANJIN_WESTERN_OF_MEDITERRANEAN_BASE_PORT",
  TIANJIN_WEST_AFRICA_BASE_PORT = "TIANJIN_WEST_AFRICA_BASE_PORT",
  TIANJIN_WEST_COAST_OF_AMERICA_BASE_PORT = "TIANJIN_WEST_COAST_OF_AMERICA_BASE_PORT",
  TIANJIN_WEST_COAST_OF_JAPAN_BASE_PORT = "TIANJIN_WEST_COAST_OF_JAPAN_BASE_PORT",
  TIANJIN_WEST_COAST_OF_SOUTH_AMERICA_BASE_PORT = "TIANJIN_WEST_COAST_OF_SOUTH_AMERICA_BASE_PORT",
  TIANJN_PERSIAN_GULF_BASE_PORT = "TIANJN_PERSIAN_GULF_BASE_PORT",
  TIANJN_SOUTH_AFRICA_BASE_PORT = "TIANJN_SOUTH_AFRICA_BASE_PORT",
}

export enum BiNums {
  BI01 = "BI01",
  BI02 = "BI02",
  BI03 = "BI03",
  BI04 = "BI04",
  BI05 = "BI05",
  BI06 = "BI06",
  BI07 = "BI07",
  BI08 = "BI08",
  BI09 = "BI09",
  BI11 = "BI11",
  BI12BF = "BI12BF",
  BI12BFC = "BI12BFC",
  BI12BFI = "BI12BFI",
  BI13 = "BI13",
  BI14IFO = "BI14IFO",
  BI14MGO = "BI14MGO",
  BI14VLSFO = "BI14VLSFO",
  BI15 = "BI15",
  BI16NSB = "BI16NSB",
  BI16NST = "BI16NST",
  BI16USB = "BI16USB",
  BI16UST = "BI16UST",
  BI17 = "BI17",
  BI18 = "BI18",
  BI19 = "BI19",
  BI20_CAPACITY = "BI20_CAPACITY",
  BI20_RATE = "BI20_RATE",
  BI21_GLOBAL = "BI21_GLOBAL",
  BI21_REGION = "BI21_REGION",
  BI22 = "BI22",
  BI23 = "BI23",
  BI24 = "BI24",
  BI24IDC = "BI24IDC",
  BI24IF = "BI24IF",
  BI25BLACKROCK = "BI25BLACKROCK",
  BI25BUSAN = "BI25BUSAN",
  BI25ICH = "BI25ICH",
  BI26 = "BI26",
  BI28DEL = "BI28DEL",
  BI28REL = "BI28REL",
  BI29WEEKLY = "BI29WEEKLY",
  BI33 = "BI33",
  BI36 = "BI36",
  BI37 = "BI37",
  BI37DAILY = "BI37DAILY",
  BI39 = "BI39",
  BI40CURRENT = "BI40CURRENT",
  BI40SCHEDULE = "BI40SCHEDULE",
  BI41 = "BI41",
  BI42 = "BI42",
  BI43 = "BI43",
  BI48 = "BI48",
}

export enum BiYears {
  Eight = "Eight",
  Five = "Five",
  Four = "Four",
  NN = "NN",
  Nine = "Nine",
  One = "One",
  Seven = "Seven",
  Six = "Six",
  Ten = "Ten",
  Three = "Three",
  Two = "Two",
}

export enum CargoSafetyFareSystemCategory {
  CARRIER_TO_SHIPPER = "CARRIER_TO_SHIPPER",
  INTER_CARRIER = "INTER_CARRIER",
  SHIPPER_TO_CARRIER = "SHIPPER_TO_CARRIER",
}

export enum DepartureAndArrival {
  Arrival = "Arrival",
  Departure = "Departure",
}

export enum EDIT_BUSINESS_WITH_ADVERTISEMENT_BY_AUTH_STATUS {
  BELOW_AUTH_STATUS = "BELOW_AUTH_STATUS",
  NO_BUSINESS_ACCOUNT = "NO_BUSINESS_ACCOUNT",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UNEXPECTED_ERROR_IN_ADVERTISEMENT = "UNEXPECTED_ERROR_IN_ADVERTISEMENT",
  UNEXPECTED_ERROR_IN_PAYMENT = "UNEXPECTED_ERROR_IN_PAYMENT",
}

export enum ExtraChargePercent {
  Bobtail_ONCE = "Bobtail_ONCE",
  Bobtail_TWICE = "Bobtail_TWICE",
  DangerousItem = "DangerousItem",
  Dangerous_Rolad = "Dangerous_Rolad",
  Dump_Container = "Dump_Container",
  FLASHBACK_CONTIAINER = "FLASHBACK_CONTIAINER",
  Gunpowder = "Gunpowder",
  Holiday = "Holiday",
  LateNight = "LateNight",
  PassSearchBar = "PassSearchBar",
  REFRIGERATED_CONTAINER = "REFRIGERATED_CONTAINER",
  RadioactiveItem = "RadioactiveItem",
  Restricte_Area = "Restricte_Area",
  TANK_CONTAINER = "TANK_CONTAINER",
  length_10cm = "length_10cm",
  length_20cm = "length_20cm",
  length_30cm = "length_30cm",
  length_40cm = "length_40cm",
  length_50cm = "length_50cm",
  lift_1t = "lift_1t",
  lift_2t = "lift_2t",
  lift_3t = "lift_3t",
  lift_4t = "lift_4t",
  lift_5t = "lift_5t",
}

export enum GdeltMainCategory {
  ISSUE = "ISSUE",
  RAWMAT = "RAWMAT",
}

export enum GetBiListType {
  ALARM = "ALARM",
  TAB = "TAB",
}

export enum GnewsOrderOptionEnum {
  NEWEST = "NEWEST",
  RANKING = "RANKING",
}

export enum IndustryFieldList {
  AirCarrier = "AirCarrier",
  Association = "Association",
  Consulting = "Consulting",
  CustomsAgent = "CustomsAgent",
  Equipment = "Equipment",
  Etc = "Etc",
  Express = "Express",
  Forwarder = "Forwarder",
  IT = "IT",
  InlandTransportationAgency = "InlandTransportationAgency",
  InspectionCertification = "InspectionCertification",
  Insurance = "Insurance",
  ManufacturerRetailer = "ManufacturerRetailer",
  PortHandling = "PortHandling",
  SeaCarrier = "SeaCarrier",
  Trucker = "Trucker",
  Warehouse = "Warehouse",
}

export enum Interval {
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum IssueRawCombinedCategory {
  DISRUPTION = "DISRUPTION",
  ECONOMIC = "ECONOMIC",
  ENERGY = "ENERGY",
  ESG = "ESG",
  GEOPOLITICAL = "GEOPOLITICAL",
  GRAIN = "GRAIN",
  MEAT = "MEAT",
  METAL = "METAL",
  NOTRELATED = "NOTRELATED",
  RAWMATERIAL = "RAWMATERIAL",
  SOFT = "SOFT",
  TRADEBARRIER = "TRADEBARRIER",
}

export enum LOCAL_LOGIN_ERROR_CODE {
  IS_DELETED = "IS_DELETED",
  IS_DORMANT = "IS_DORMANT",
  NO_ACCOUNT = "NO_ACCOUNT",
  NO_INTEGRATION_USER = "NO_INTEGRATION_USER",
  NO_MATCH_LOGIN_TYPE = "NO_MATCH_LOGIN_TYPE",
  NO_MATCH_PASSWORD = "NO_MATCH_PASSWORD",
  OCCUPIED_OTHER_SERVICES = "OCCUPIED_OTHER_SERVICES",
  OLD_ACCOUNT_AND_NO_PROFILE = "OLD_ACCOUNT_AND_NO_PROFILE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum LogisticTableList {
  LCR = "LCR",
}

export enum MainCategoryEnum {
  CommunityPost = "CommunityPost",
  FreePost = "FreePost",
  NewsCenterPost = "NewsCenterPost",
  NoticePost = "NoticePost",
  OpinionPost = "OpinionPost",
  QuestionPost = "QuestionPost",
  RecruitmentPost = "RecruitmentPost",
  ReferencePost = "ReferencePost",
  SupportPost = "SupportPost",
}

export enum MessageType {
  ReceiveMessage = "ReceiveMessage",
  SendMessage = "SendMessage",
}

export enum NewsTopicCategory {
  AIRFREIGHT = "AIRFREIGHT",
  COMPLEXFREIGHT = "COMPLEXFREIGHT",
  ECONOMY = "ECONOMY",
  OCEANFREIGHT = "OCEANFREIGHT",
  SCM = "SCM",
  TRADE = "TRADE",
}

export enum OagChartCategory {
  AIRLINES = "AIRLINES",
  AIRPORTS = "AIRPORTS",
  REGION = "REGION",
}

export enum OrderOption {
  CommentDesc = "CommentDesc",
  LikeDesc = "LikeDesc",
  ViewDesc = "ViewDesc",
}

export enum PAYMENT_STATUS_ENUM {
  BEFORE_VERIFICATION = "BEFORE_VERIFICATION",
  BEFORE_VERIFICATION_PENALTY = "BEFORE_VERIFICATION_PENALTY",
  BEFORE_VERIFICATION_REFUND = "BEFORE_VERIFICATION_REFUND",
  BEFORE_VERIFICATION_ROLLBACK = "BEFORE_VERIFICATION_ROLLBACK",
  BEFORE_VERIFICATION_UPGRADE = "BEFORE_VERIFICATION_UPGRADE",
  CANCELLED = "CANCELLED",
  CANCELLED_SCHEDULE = "CANCELLED_SCHEDULE",
  PAYMENT_FAIL = "PAYMENT_FAIL",
  PENALTY_SUCCESS = "PENALTY_SUCCESS",
  PENDING = "PENDING",
  PENDING_ROLLBACK = "PENDING_ROLLBACK",
  PENDING_UPGRADE = "PENDING_UPGRADE",
  REFUND_SUCCESS = "REFUND_SUCCESS",
  SCHEDULE_FAIL = "SCHEDULE_FAIL",
  SUCCESS = "SUCCESS",
  WILL_EXPIRED = "WILL_EXPIRED",
}

export enum PERIOD_UNIT_TYPE {
  MONTH = "MONTH",
  WEEK = "WEEK",
}

export enum PRODUCT_SUBCATEGORY_ENUM {
  SUB_MONTHLY = "SUB_MONTHLY",
  SUB_PER_MINUTES_TEST = "SUB_PER_MINUTES_TEST",
  SUB_YEARLY = "SUB_YEARLY",
}

export enum PortComplexityEnum {
  critical = "critical",
  high = "high",
  low = "low",
  medium = "medium",
}

export enum PortName {
  BUSAN_NEW_PORT_AND_UIWANG_ICD_ONE_WAY = "BUSAN_NEW_PORT_AND_UIWANG_ICD_ONE_WAY",
  BUSAN_NEW_PORT_ROUND_TRIP = "BUSAN_NEW_PORT_ROUND_TRIP",
  BUSAN_NORTH_PORT_AND_UIWANG_ICD_ONE_WAY = "BUSAN_NORTH_PORT_AND_UIWANG_ICD_ONE_WAY",
  BUSAN_NORTH_PORT_ROUND_TRIP = "BUSAN_NORTH_PORT_ROUND_TRIP",
  DAESAN_PORT_ROUND_TRIP = "DAESAN_PORT_ROUND_TRIP",
  DISTANCE_ROUND_TRIP = "DISTANCE_ROUND_TRIP",
  GUNSAN_PORT_ROUND_TRIP = "GUNSAN_PORT_ROUND_TRIP",
  GWANGYANG_PORT_AND_UIWANG_ICD_ONE_WAY = "GWANGYANG_PORT_AND_UIWANG_ICD_ONE_WAY",
  GWANGYANG_PORT_ROUND_TRIP = "GWANGYANG_PORT_ROUND_TRIP",
  INCHEON_DISTANCE_ROUND_TRIP = "INCHEON_DISTANCE_ROUND_TRIP",
  INCHEON_NEW_PORT_ROUND_TRIP = "INCHEON_NEW_PORT_ROUND_TRIP",
  INCHEON_PORT_INTERNATIONAL_PASSENGER_TERMINAL_ROUND_TRIP = "INCHEON_PORT_INTERNATIONAL_PASSENGER_TERMINAL_ROUND_TRIP",
  INCHEON_PORT_ROUND_TRIP = "INCHEON_PORT_ROUND_TRIP",
  MASAN_PORT_ROUND_TRIP = "MASAN_PORT_ROUND_TRIP",
  POHANG_PORT_ROUND_TRIP = "POHANG_PORT_ROUND_TRIP",
  PYEONGTAEK_DISTANCE_ROUND_TRIP = "PYEONGTAEK_DISTANCE_ROUND_TRIP",
  PYEONGTAEK_PORT_ROUND_TRIP = "PYEONGTAEK_PORT_ROUND_TRIP",
  UIWANG_ICD_ROUND_TRIP = "UIWANG_ICD_ROUND_TRIP",
  ULSAN_NEW_PORT_ROUND_TRIP = "ULSAN_NEW_PORT_ROUND_TRIP",
  ULSAN_OLD_PORT_ROUND_TRIP = "ULSAN_OLD_PORT_ROUND_TRIP",
}

export enum RESET_PASSWORD_ERR_CODE {
  FAIL_RESET_PASSWORD = "FAIL_RESET_PASSWORD",
  IS_DELETED = "IS_DELETED",
  IS_DORMANT = "IS_DORMANT",
  LINK_EXPIRED = "LINK_EXPIRED",
  NO_CUSTPIA_USER = "NO_CUSTPIA_USER",
  NO_MATCH_LOGIN_TYPE = "NO_MATCH_LOGIN_TYPE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum ReasonWhyReceivedExperienceOrPointOrAdvertisementEnum {
  ATTEND = "ATTEND",
  CREATE_COMMENT = "CREATE_COMMENT",
  CREATE_OPINION_POST = "CREATE_OPINION_POST",
  CREATE_POST = "CREATE_POST",
  GET_COMMENT_BY_POST = "GET_COMMENT_BY_POST",
  GET_LIKE_BY_COMMENT = "GET_LIKE_BY_COMMENT",
  GET_LIKE_BY_POST = "GET_LIKE_BY_POST",
  GET_SCRAP_BY_POST = "GET_SCRAP_BY_POST",
  GET_VIEW_BY_POST = "GET_VIEW_BY_POST",
  INPUT_REFCODE_BY_MYSELF = "INPUT_REFCODE_BY_MYSELF",
  INPUT_REFCODE_BY_OTHERS = "INPUT_REFCODE_BY_OTHERS",
  L1 = "L1",
  L10 = "L10",
  L11 = "L11",
  L12 = "L12",
  L13 = "L13",
  L14 = "L14",
  L15 = "L15",
  L2 = "L2",
  L3 = "L3",
  L4 = "L4",
  L5 = "L5",
  L6 = "L6",
  L7 = "L7",
  L8 = "L8",
  L9 = "L9",
  PREMIUM_BANNER = "PREMIUM_BANNER",
  SIGN_UP = "SIGN_UP",
}

export enum ReportPostType {
  ANOTHER_CATEGORY = "ANOTHER_CATEGORY",
  BREAKING_RULES = "BREAKING_RULES",
  CONTENT_OF_DISCOMFORT = "CONTENT_OF_DISCOMFORT",
  ETC = "ETC",
  OVERLAPPING_POSTS = "OVERLAPPING_POSTS",
  PROMOTION = "PROMOTION",
}

export enum SEND_EMAIL_FOR_DELETE_USER_AUTH_CODE {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum SEND_EMAIL_RESET_PASSWORD_ERR_CODE {
  DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
  IS_DELETED = "IS_DELETED",
  IS_DORMANT = "IS_DORMANT",
  NO_LARCHIVE_USER = "NO_LARCHIVE_USER",
  NO_MATCH_LOGIN_TYPE = "NO_MATCH_LOGIN_TYPE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum SEND_EMAIL_VERIFIED_LINK_ERR_CODE {
  ALREADY_VERIFIED = "ALREADY_VERIFIED",
  TOO_MANY_ATTEMPTS_TRY_LATER = "TOO_MANY_ATTEMPTS_TRY_LATER",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum SOFT_DELETE_PROFILE_AND_USER_ERR_CODE {
  EXPIRE_AUTH_TIME = "EXPIRE_AUTH_TIME",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum SUBSCRIPTION_LIMIT_TYPE {
  API_CALL = "API_CALL",
  BOOLEAN = "BOOLEAN",
  CNT = "CNT",
  DISCOUNT = "DISCOUNT",
  PERIOD = "PERIOD",
  VERSION = "VERSION",
}

export enum SWITCH_BUSINESS_ACCOUNT_FROM_PERSONAL_ERR_CODE {
  ALREADY_BUSINESS_ACCOUNT = "ALREADY_BUSINESS_ACCOUNT",
  ALREADY_ENROLLED_REGISTER_NO = "ALREADY_ENROLLED_REGISTER_NO",
  CLOSING_BUSINESS_ERROR = "CLOSING_BUSINESS_ERROR",
  CREATE_BUSINESS_ERR = "CREATE_BUSINESS_ERR",
  EMPTY_STATUS = "EMPTY_STATUS",
  NO_MATCH = "NO_MATCH",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum SearchOption {
  Content = "Content",
  Title = "Title",
  TitleOrContent = "TitleOrContent",
  WriterNickname = "WriterNickname",
}

export enum Sns {
  Google = "Google",
  Kakao = "Kakao",
  Local = "Local",
  Naver = "Naver",
}

export enum SpecializedServices {
  AIR_Charter = "AIR_Charter",
  AIR_DangerousGoods = "AIR_DangerousGoods",
  AIR_Express = "AIR_Express",
  AIR_HighValue = "AIR_HighValue",
  AIR_Live = "AIR_Live",
  AIR_Reefer = "AIR_Reefer",
  AIR_SeaAndAir = "AIR_SeaAndAir",
  AIR_Sensitive = "AIR_Sensitive",
  CUSTOMS_AEO = "CUSTOMS_AEO",
  CUSTOMS_Certificate = "CUSTOMS_Certificate",
  CUSTOMS_CustomsClearance = "CUSTOMS_CustomsClearance",
  CUSTOMS_Dispute = "CUSTOMS_Dispute",
  CUSTOMS_DutyDrawback = "CUSTOMS_DutyDrawback",
  CUSTOMS_FTAConsulting = "CUSTOMS_FTAConsulting",
  CUSTOMS_Inspection = "CUSTOMS_Inspection",
  ETC = "ETC",
  INLAND_BondedTransport = "INLAND_BondedTransport",
  INLAND_DangerousGoods = "INLAND_DangerousGoods",
  INLAND_HighValue = "INLAND_HighValue",
  INLAND_Reefer = "INLAND_Reefer",
  INLAND_Sensitive = "INLAND_Sensitive",
  INLAND_TCR = "INLAND_TCR",
  INLAND_TMGR = "INLAND_TMGR",
  INLAND_TMR = "INLAND_TMR",
  INLAND_TSR = "INLAND_TSR",
  SEA_Bulk = "SEA_Bulk",
  SEA_DangerousGoods = "SEA_DangerousGoods",
  SEA_Ferry = "SEA_Ferry",
  SEA_OOG = "SEA_OOG",
  SEA_Reefer = "SEA_Reefer",
  SEA_RoRo = "SEA_RoRo",
  SEA_SeaAndAir = "SEA_SeaAndAir",
  SEA_Tanker = "SEA_Tanker",
  WAREHOUSE_BondedWarehouse = "WAREHOUSE_BondedWarehouse",
  WAREHOUSE_CFSstorage = "WAREHOUSE_CFSstorage",
  WAREHOUSE_DangerousGoods = "WAREHOUSE_DangerousGoods",
  WAREHOUSE_Fulfillment = "WAREHOUSE_Fulfillment",
  WAREHOUSE_ICD_Depot = "WAREHOUSE_ICD_Depot",
  WAREHOUSE_Packaging = "WAREHOUSE_Packaging",
  WAREHOUSE_Reefer = "WAREHOUSE_Reefer",
  WAREHOUSE_Sensitive = "WAREHOUSE_Sensitive",
}

export enum SubCategoryEnum {
  Community_Free = "Community_Free",
  Community_NoticeAndEvent = "Community_NoticeAndEvent",
  Community_SignupGreeting = "Community_SignupGreeting",
  NewsCenter_EmergencyReport = "NewsCenter_EmergencyReport",
  NewsCenter_Etc = "NewsCenter_Etc",
  NewsCenter_LogisticsMarketConditions = "NewsCenter_LogisticsMarketConditions",
  NewsCenter_LogisticsNews = "NewsCenter_LogisticsNews",
  NewsCenter_TodayNews = "NewsCenter_TodayNews",
  NewsCenter_TradeNews = "NewsCenter_TradeNews",
  Opinion_Etc = "Opinion_Etc",
  Question_Air_Express = "Question_Air_Express",
  Question_Customs = "Question_Customs",
  Question_Etc = "Question_Etc",
  Question_In_land = "Question_In_land",
  Question_Sea_Rail = "Question_Sea_Rail",
  Question_Trade_Commerce = "Question_Trade_Commerce",
  Question_Translation = "Question_Translation",
  Question_Warehouse = "Question_Warehouse",
  Recruitment_CustomsAgent = "Recruitment_CustomsAgent",
  Recruitment_Etc = "Recruitment_Etc",
  Recruitment_Forwarder = "Recruitment_Forwarder",
  Recruitment_InlandTransportation = "Recruitment_InlandTransportation",
  Recruitment_SeaCarrierAirCarrier = "Recruitment_SeaCarrierAirCarrier",
  Recruitment_Shipper = "Recruitment_Shipper",
  Recruitment_ThreePL = "Recruitment_ThreePL",
  SUPPORT_A = "SUPPORT_A",
  SUPPORT_B = "SUPPORT_B",
  SUPPORT_C = "SUPPORT_C",
}

export enum SubjectEnum {
  DISTRIBUTION_ETC = "DISTRIBUTION_ETC",
  DISTRIBUTION_FLIGHT = "DISTRIBUTION_FLIGHT",
  DISTRIBUTION_GROUND = "DISTRIBUTION_GROUND",
  DISTRIBUTION_POLICY = "DISTRIBUTION_POLICY",
  DISTRIBUTION_SEA = "DISTRIBUTION_SEA",
  DISTRIBUTION_WAREHOUSE = "DISTRIBUTION_WAREHOUSE",
  TRADE_FOREIGN_EXCHANGE = "TRADE_FOREIGN_EXCHANGE",
  TRADE_FTA = "TRADE_FTA",
  TRADE_MARKET = "TRADE_MARKET",
  TRADE_POLICY = "TRADE_POLICY",
}

export enum SubscriptionType {
  BASIC = "BASIC",
  ENTERPRISE = "ENTERPRISE",
  FREE = "FREE",
  PRO = "PRO",
  STANDARD = "STANDARD",
  TRIAL_STANDARD = "TRIAL_STANDARD",
}

export enum TemperatureEnum {
  ColdTemp = "ColdTemp",
  IceTemp = "IceTemp",
  RoomTemp = "RoomTemp",
}

export enum TemplateRowType {
  ANOMALY = "ANOMALY",
  COMPARISON = "COMPARISON",
  COMPOSITE = "COMPOSITE",
}

export enum TerminalCargoStatus {
  ARRIVED = "ARRIVED",
  CUT_OFF = "CUT_OFF",
  DEPARTED = "DEPARTED",
  ETC = "ETC",
  OPEN = "OPEN",
}

export enum UPDATE_BUSINESS_REGISTRATION_PAPER_ERR_CODE {
  ALREADY_ENROLLED_REGISTER_NO = "ALREADY_ENROLLED_REGISTER_NO",
  NO_AUTH_STATUS = "NO_AUTH_STATUS",
  NO_BUSINESS_ACCOUNT = "NO_BUSINESS_ACCOUNT",
  UNEXPECTED_ERR = "UNEXPECTED_ERR",
  UPDATE_FAIL = "UPDATE_FAIL",
}

export enum UPDATE_NICKNAME_ERR_CODE {
  ALREADY_USED_NICKNAME = "ALREADY_USED_NICKNAME",
  FORBIDDEN_WORD = "FORBIDDEN_WORD",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UPDATE_FAIL = "UPDATE_FAIL",
}

export enum UPDATE_TEL_ERR_CODE {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UPDATE_FAIL = "UPDATE_FAIL",
}

export enum UPDATE_USER_PASSWORD_IN_MYPAGE_ERR_CODE {
  NO_MATCH_EMAIL_AND_IDTOKEN = "NO_MATCH_EMAIL_AND_IDTOKEN",
  NO_MATCH_SIGNUP_TYPE = "NO_MATCH_SIGNUP_TYPE",
  NO_USER = "NO_USER",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum UPDATE_USER_TYPE_ERR_CODE {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UPDATE_FAIL = "UPDATE_FAIL",
}

export enum USE_LARCHIVE_FROM_OTHER_SERVICE_ERROR_CODE {
  ALREADY_SIGNED_IN_LARCHIVE = "ALREADY_SIGNED_IN_LARCHIVE",
  ALREADY_SIGNED_IN_OTHER_SERVICE = "ALREADY_SIGNED_IN_OTHER_SERVICE",
  IS_DELETED = "IS_DELETED",
  IS_DORMANT = "IS_DORMANT",
  NO_ACCOUNT = "NO_ACCOUNT",
  NO_EMAIL = "NO_EMAIL",
  NO_INTEGRATION_USER = "NO_INTEGRATION_USER",
  NO_MATCH_LOGIN_TYPE = "NO_MATCH_LOGIN_TYPE",
  NO_MATCH_PASSWORD = "NO_MATCH_PASSWORD",
  OCCUPIED_OTHER_SERVICES = "OCCUPIED_OTHER_SERVICES",
  OLD_ACCOUNT_AND_NO_PROFILE = "OLD_ACCOUNT_AND_NO_PROFILE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum VERSION_TYPE {
  basic = "basic",
  pro = "pro",
}

export enum Widget {
  EXCHANGE_RATE_INFO = "EXCHANGE_RATE_INFO",
  NATIONAL_HOLIDAY_LIST = "NATIONAL_HOLIDAY_LIST",
  WORLD_TIME = "WORLD_TIME",
  WORLD_WEATHER_INFO = "WORLD_WEATHER_INFO",
}

export enum bi19 {
  CHICAGOTOLA = "CHICAGOTOLA",
  CHICAGOTONJ = "CHICAGOTONJ",
  DALLASTOLA = "DALLASTOLA",
  DALLASTONJ = "DALLASTONJ",
  EASTTOWEST = "EASTTOWEST",
  LATOCHICAGO = "LATOCHICAGO",
  LATODALLAS = "LATODALLAS",
  MWTONE = "MWTONE",
  MWTONW = "MWTONW",
  MWTOSE = "MWTOSE",
  MWTOSW = "MWTOSW",
  NETOMW = "NETOMW",
  NETOSW = "NETOSW",
  NJTOCHICAGO = "NJTOCHICAGO",
  NJTODALLAS = "NJTODALLAS",
  NWTOMW = "NWTOMW",
  SETOMW = "SETOMW",
  SETOSW = "SETOSW",
  SWTOMW = "SWTOMW",
  SWTONE = "SWTONE",
  SWTOSE = "SWTOSE",
  WESTTOEAST = "WESTTOEAST",
}

export enum bi20_capacity {
  BULK = "BULK",
  FLATBED = "FLATBED",
  REEFER = "REEFER",
  VAN = "VAN",
}

export enum bi20_rate {
  BULK = "BULK",
  FLATBED = "FLATBED",
  REEFER = "REEFER",
  SUB_CONTRACT = "SUB_CONTRACT",
  SUB_SPOT = "SUB_SPOT",
  VAN = "VAN",
}

export enum bi21_global {
  BULK = "BULK",
  PRICE_INDEX = "PRICE_INDEX",
  TEU_VOLUME = "TEU_VOLUME",
}

export enum bi21_region {
  AUSTRAILIA_OCEANIA = "AUSTRAILIA_OCEANIA",
  BULK = "BULK",
  EUROPE = "EUROPE",
  EXPORT = "EXPORT",
  FAREAST = "FAREAST",
  IMPORT = "IMPORT",
  INDIAN_SUB_CONT_MIDDLE_EAST = "INDIAN_SUB_CONT_MIDDLE_EAST",
  NORTH_AMERICA = "NORTH_AMERICA",
  SOUTH_CENTRAL_AMERICA = "SOUTH_CENTRAL_AMERICA",
  SUB_SAHARAN_AFRICA = "SUB_SAHARAN_AFRICA",
}

export enum bi22 {
  AUSTRALIA = "AUSTRALIA",
  BRAZIL = "BRAZIL",
  CANADA = "CANADA",
  CHINA = "CHINA",
  COLOMBIA = "COLOMBIA",
  CZECHREPUBLIC = "CZECHREPUBLIC",
  DENMARK = "DENMARK",
  EGYPT = "EGYPT",
  EUROAREA = "EUROAREA",
  FRANCE = "FRANCE",
  GERMANY = "GERMANY",
  GREECE = "GREECE",
  HONGKONG = "HONGKONG",
  HUNGARY = "HUNGARY",
  INDIA = "INDIA",
  INDONESIA = "INDONESIA",
  IRELAND = "IRELAND",
  ITALY = "ITALY",
  JAPAN = "JAPAN",
  KENYA = "KENYA",
  MALAYSIA = "MALAYSIA",
  MEXICO = "MEXICO",
  MYANMAR = "MYANMAR",
  NETHERLANDS = "NETHERLANDS",
  PHILIPPINES = "PHILIPPINES",
  POLAND = "POLAND",
  RUSSIANFEDERATION = "RUSSIANFEDERATION",
  SAUDIARABIA = "SAUDIARABIA",
  SINGAPORE = "SINGAPORE",
  SOUTHAFRICA = "SOUTHAFRICA",
  SOUTHKOREA = "SOUTHKOREA",
  SPAIN = "SPAIN",
  SRILANKA = "SRILANKA",
  SWEDEN = "SWEDEN",
  SWITZERLAND = "SWITZERLAND",
  THAILAND = "THAILAND",
  TURKEY = "TURKEY",
  UNITEDARABEMIRATES = "UNITEDARABEMIRATES",
  UNITEDKINGDOM = "UNITEDKINGDOM",
  UNITEDSTATES = "UNITEDSTATES",
  VIETNAM = "VIETNAM",
}

export enum bi23 {
  AMSTERDAM_USA = "AMSTERDAM_USA",
  BULK = "BULK",
  FRANKFURT_CHINA = "FRANKFURT_CHINA",
  HONGKONG_USA = "HONGKONG_USA",
  MUMBAI_UK = "MUMBAI_UK",
  SHANGHAI_GERMANY = "SHANGHAI_GERMANY",
  WORLDWIDE = "WORLDWIDE",
}

export enum bi24 {
  AFRICA = "AFRICA",
  ASIA_PACIFIC = "ASIA_PACIFIC",
  EUROPE = "EUROPE",
  LATIN_AMERICA = "LATIN_AMERICA",
  MIDDLE_EAST = "MIDDLE_EAST",
  NORTH_AMERICA = "NORTH_AMERICA",
  TOTAL = "TOTAL",
  TYPEACTKS = "TYPEACTKS",
  TYPECLF = "TYPECLF",
  TYPECTKS = "TYPECTKS",
}

export enum bi33 {
  INDEXES = "INDEXES",
}

export enum bi37daily {
  FIVESEVEN = "FIVESEVEN",
  FOURTWOFIEV = "FOURTWOFIEV",
  ONEONE = "ONEONE",
  ONESEVEN = "ONESEVEN",
  SIXFIVE = "SIXFIVE",
  THREEFIVE = "THREEFIVE",
  TWOFIVE = "TWOFIVE",
  TWOSEVEN = "TWOSEVEN",
}

export enum bi39 {
  AUSTRALIA_NEW_ZEALAND_SERVICE = "AUSTRALIA_NEW_ZEALAND_SERVICE",
  COMPREHENSIVE_INDEX = "COMPREHENSIVE_INDEX",
  EUROPE_SERVICE = "EUROPE_SERVICE",
  E_C_AMERICA_SERVICE = "E_C_AMERICA_SERVICE",
  JAPAN_SERVICE = "JAPAN_SERVICE",
  KOREA_SERVICE = "KOREA_SERVICE",
  MEDITERRANEAN_SERVICE = "MEDITERRANEAN_SERVICE",
  PERSIAN_GULF_RED_SEA_SERVICE = "PERSIAN_GULF_RED_SEA_SERVICE",
  SOUTHEAST_ASIA_SERVICE = "SOUTHEAST_ASIA_SERVICE",
  SOUTH_AFRICA_SERVICE = "SOUTH_AFRICA_SERVICE",
  SOUTH_AMERICA_SERVICE = "SOUTH_AMERICA_SERVICE",
  WEST_EAST_AFRICA_SERVICE = "WEST_EAST_AFRICA_SERVICE",
  W_C_AMERICA_SERVICE = "W_C_AMERICA_SERVICE",
}

export enum bi42 {
  BULK = "BULK",
  COMPOSITE = "COMPOSITE",
  FRA = "FRA",
  HKG = "HKG",
  LHR = "LHR",
  ORD = "ORD",
  PVG = "PVG",
  SIN = "SIN",
}

export interface ActivateDormantAccountInputDto {
  email: string;
  isSns: boolean;
}

export interface AddTeamMemberInputDto {
  emailList: string[];
}

/**
 * 이상탐지용 데이터
 */
export interface AnomalyBiInfoV2InputType {
  routeId: number;
  sensitivity: number;
}

export interface ApiCallLimitTypeInputType {
  period: number;
  period_unit: PERIOD_UNIT_TYPE;
  limit_number: number;
}

export interface AttachmentInputType {
  filePath: string;
  fileName: string;
}

export interface AttendInputDto {
  timezoneOffset: number;
}

export interface BasicAlarmInfoInputType {
  biRouteId: number;
  biCategoryId?: string | null;
}

export interface Bi29DailyInputDto {
  date: string;
}

export interface Bi29ExtraInfoOfPortInput {
  date: string;
  port_code: string;
}

export interface Bi48InputDto {
  from: Bi48;
  to?: Bi48 | null;
  includeCultumutive?: boolean | null;
}

export interface Bi50Input {
  interval: Interval;
  from: number;
  category: Bi50Category[];
}

export interface Bi53Input {
  interval: Interval;
  from: number;
  routes: Bi53Route[];
}

export interface BiCodes {
  bi01?: Bi01[] | null;
  bi02?: Bi02[] | null;
  bi03?: Bi03[] | null;
  bi04?: Bi04[] | null;
  bi05?: Bi05[] | null;
  bi06?: Bi06[] | null;
  bi07?: Bi07[] | null;
  bi08?: Bi08[] | null;
  bi09?: Bi09[] | null;
  bi11?: Bi11[] | null;
  bi12bf?: Bi12BF[] | null;
  bi12bfi?: Bi12BFI[] | null;
  bi12bfc?: Bi12BFC[] | null;
  bi13?: Bi13[] | null;
  bi14ifo?: Bi14IFO[] | null;
  bi14vlsfo?: Bi14VLSFO[] | null;
  bi14mgo?: Bi14MGO[] | null;
  bi15?: Bi15[] | null;
  bi16nsb?: Bi16NSB[] | null;
  bi16usb?: Bi16USB[] | null;
  bi16nst?: Bi16NST[] | null;
  bi16ust?: Bi16UST[] | null;
  bi17?: Bi17[] | null;
  bi18?: Bi18[] | null;
  bi19?: bi19[] | null;
  bi20_rate?: bi20_rate[] | null;
  bi20_capacity?: bi20_capacity[] | null;
  bi21_global?: bi21_global[] | null;
  bi21_region?: bi21_region[] | null;
  bi22?: bi22[] | null;
  bi23?: bi23[] | null;
  bi24?: bi24[] | null;
  bi24idc?: Bi24IDC[] | null;
  bi24if?: Bi24IF[] | null;
  bi25ich?: Bi25ICH[] | null;
  bi25busan?: Bi25BUSAN[] | null;
  bi25blackrock?: BI25BLACKROCK[] | null;
  bi26?: Bi26[] | null;
  bi28rel?: string | null;
  bi28del?: string | null;
  bi29weekly?: string | null;
  bi33?: bi33[] | null;
  bi36?: Bi36[] | null;
  bi37?: Bi37[] | null;
  bi39?: bi39[] | null;
  bi37daily?: bi37daily[] | null;
  bi40current?: Bi40CURRENT[] | null;
  bi40schedule?: Bi40SCHEDULE[] | null;
  bi41?: Bi41[] | null;
  bi42?: bi42[] | null;
  bi43?: Bi43[] | null;
  bi48?: Bi48[] | null;
}

export interface BiDataInputDto {
  query?: BiCodes | null;
  code: BiNums;
  year: BiYears;
}

export interface BiInfoInputType {
  biName: string;
  biId: number;
}

export interface BiRouteWithCategoryInputType {
  biCategoryId?: string | null;
  biRouteList: number[];
}

export interface BooleanLimitTypeInputType {
  boolean: boolean;
}

export interface BranchInfoInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name?: string | null;
  tel?: string | null;
  email?: string | null;
  address?: string | null;
}

export interface BusinessGiveLikeInputDto {
  id?: number | null;
}

export interface BusinessInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  connectedUserList?: UserInputType[] | null;
  businessName: string;
  ceoName?: string | null;
  address?: string | null;
  businessRegisterNo: string;
  businessTel?: string | null;
  businessEmail: string;
  businessType?: IndustryFieldList | null;
  representImage?: string | null;
  logo?: string | null;
  representSentence?: string | null;
  link?: string | null;
  businessScale?: string | null;
  businessStartDate?: any | null;
  representContent?: string | null;
  specializedServiceList?: SpecializedServices[] | null;
  authList?: AuthList[] | null;
  brochureTitle?: string | null;
  brochurePath?: string | null;
  primaryCountryList?: string[] | null;
  specializedItem?: string[] | null;
  majorCustomers?: string[] | null;
  auth_status: number;
  business_registration_paper_url?: string | null;
  likeCount: number;
  viewCount: number;
  businessManagerList?: BusinessManagerInputType[] | null;
  branchInfoList?: BranchInfoInputType[] | null;
  specializedServiceSEA?: (SpecializedServices | null)[] | null;
  specializedServiceAIR?: (SpecializedServices | null)[] | null;
  specializedServiceINLAND?: (SpecializedServices | null)[] | null;
  specializedServiceWAREHOUSE?: (SpecializedServices | null)[] | null;
  specializedServiceCUSTOMS?: (SpecializedServices | null)[] | null;
  promotionSentence?: string | null;
  isLikedByMyself?: boolean | null;
}

export interface BusinessManagerInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  position?: string | null;
  task?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
}

export interface CancelSubscriptionInputDto {
  reason: string;
}

export interface CareerInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  position: string;
  company: string;
  companyJoinedYear: string;
  companyJoinedMonth: string;
  companyQuittedYear?: string | null;
  companyQuittedMonth?: string | null;
  isCurrentCompany?: boolean | null;
  user?: UserInputType | null;
}

export interface ChangeSubscriptionMembershipInputDto {
  productId: number;
}

export interface ChangeSubscriptionPaymentMethodInputDto {
  newBillingKey: string;
}

export interface ChangeSubscriptionWithTeamPlanInputDto {
  billingKey?: string | null;
  productId: number;
  expirationPeriodMinutes?: number | null;
  numberOfTeamMember?: number | null;
}

/**
 * 지수 별 차트 내 위치 정보
 */
export interface ChartCoordinateInputType {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface CheckUserByEmailInputDto {
  email: string;
}

export interface ClickedPowerLinkInputDto {
  advertisementId: number;
}

export interface CntLimitTypeInputType {
  limit_number?: number | null;
}

/**
 * 비교분석용 데이터. FetchBi에서 가져오는 것을 원칙으로 하지만, 삭제의 경우는 싱크를 맞추지 않는다.
 */
export interface ComparisonBiInfoInputType {
  chartNum?: number | null;
  singleBi?: SingleBiWithoutChartNumInputType | null;
  multipleBi?: SingleBiWithoutChartNumInputType[] | null;
}

/**
 * 비교분석용 데이터. FetchBi에서 가져오는 것을 원칙으로 하지만, 삭제의 경우는 싱크를 맞추지 않는다.
 */
export interface ComparisonBiInfoV2InputType {
  chartName: string;
  biList: number[];
  chartNum?: number | null;
  coordinate: ChartCoordinateInputType;
  multipleBi?: SingleBiRouteWithCategoryInputType[] | null;
}

export interface CreateCommentInputDto {
  postId: string;
  parentPostId?: string | null;
  content: string;
  parentCommentId?: string | null;
  mainCategory?: MainCategoryEnum | null;
}

export interface CreateLocalAccountInputDto {
  email: string;
  agreementTermsConditionsPersonalInformationAt: any;
  password: string;
}

export interface CreateMailSubscriptionInfoInputDto {
  email: string;
  userName?: string | null;
}

export interface CreateMydashboardAlarmInputDto {
  chartKey: BasicAlarmInfoInputType;
  changeRate: number;
}

export interface CreateNewPaymentForSubscriptionInputDto {
  billingKey?: string | null;
  productId: number;
  expirationPeriodMinutes?: number | null;
}

export interface CreateNewPaymentWithTeamPlanForSubscriptionInputDto {
  billingKey?: string | null;
  productId: number;
  expirationPeriodMinutes?: number | null;
  numberOfTeamMember?: number | null;
}

export interface CreatePostInputDto {
  title: string;
  htmlContent: string;
  textContent: string;
  fileList?: FileInputType[] | null;
  thumbnailImagePath?: string | null;
  mainCategory: MainCategoryEnum;
  hashtagList: string[];
  parentPostId?: string | null;
}

export interface DeleteCommentInputDto {
  _id: string;
  mainCategory?: MainCategoryEnum | null;
}

export interface DeleteMessageInputDto {
  id?: number | null;
  isReceivedMessage: boolean;
}

export interface DeleteMydashboardAlarmInputDto {
  id?: number | null;
}

export interface DeleteMydashboardTemplateInputDto {
  templateId: number;
}

export interface DeletePostInputDto {
  _id?: string | null;
  mainCategory: MainCategoryEnum;
}

export interface DeleteTeamMemberInputDto {
  email: string;
}

export interface DisasterInput {
  port_code: string;
}

export interface EditBusinessAndAdvertisementByAuthStatusInputDto {
  ceoName?: string | null;
  address?: string | null;
  businessTel?: string | null;
  businessType?: IndustryFieldList | null;
  representImage?: string | null;
  logo?: string | null;
  representSentence?: string | null;
  link?: string | null;
  businessScale?: string | null;
  businessStartDate?: any | null;
  representContent?: string | null;
  specializedServiceList?: SpecializedServices[] | null;
  authList?: AuthList[] | null;
  brochureTitle?: string | null;
  brochurePath?: string | null;
  primaryCountryList?: string[] | null;
  majorCustomers?: string[] | null;
  businessManagerList?: BusinessManagerInputType[] | null;
  branchInfoList?: BranchInfoInputType[] | null;
  promotionSentence?: string | null;
}

export interface EditCommentInputDto {
  _id: string;
  content: string;
}

export interface EditPostInputDto {
  _id: string;
  title: string;
  htmlContent: string;
  textContent: string;
  fileList?: FileInputType[] | null;
  thumbnailImagePath?: string | null;
  mainCategory: MainCategoryEnum;
  hashtagList: string[];
}

export interface EditProfilePhotoInputDto {
  profilePhoto: string;
}

export interface EditUserProfileInputDto {
  nickname?: string | null;
  tel?: string | null;
  agreementReceivingEventsAndMarketingAt?: any | null;
  userType?: IndustryFieldList | null;
  interest?: string[] | null;
  introduction?: string | null;
  careerList?: CareerInputType[] | null;
  educationList?: EducationInputType[] | null;
}

export interface EducationInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  major?: string | null;
  school: string;
  admissionYear: string;
  graduationYear?: string | null;
  isCurrentSchool?: boolean | null;
  user?: UserInputType | null;
}

export interface FCLViewInputDto {
  departureLocode: string;
  arrivalLocode: string;
  departureDate: any;
}

export interface FileInputType {
  fileName?: string | null;
  filePath?: string | null;
}

export interface GetAdListInputDto {
  adType: AdType;
}

export interface GetAttendanceListInputDto {
  date: any;
}

export interface GetBiBulkDataInputDto {
  biName: string;
}

export interface GetBiCommentInputDto {
  biName: string;
}

export interface GetBiIndividualInputDto {
  biRouteId: number;
  biCategoryId?: string | null;
}

export interface GetBiListInputDto {
  type?: GetBiListType | null;
}

export interface GetBiMetadataInputDto {
  biName: string;
}

export interface GetBiPredictionDataInputDto {
  biCode: string;
}

export interface GetBulkGlobalMineralPricesInputDto {
  daysInterval?: number | null;
  category: string;
}

export interface GetCommentListByUserIdInputDto {
  writerId: number;
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface GetCommentListInputDto {
  postId: string;
}

export interface GetContainerFareDataInputDto {
  code: string;
  year?: string | null;
  countryName?: string[] | null;
}

export interface GetGlobalChokePointDataInputDto {
  start_date: string;
  end_date: string;
}

export interface GetGptTreemapDataInputDto {
  currentDate: string;
  mainCategory: GdeltMainCategory;
}

export interface GetHolidayListInputDto {
  year: number;
  month?: number | null;
  day?: number | null;
}

export interface GetIndividualGlobalMineralPricesInputDto {
  category: string;
  route?: string | null;
  routeType?: string | null;
}

export interface GetIssueMonitorNewsListInputDto {
  page: number;
  category: IssueRawCombinedCategory;
  keyword: string;
}

export interface GetIssueToneListInputDto {
  category: IssueRawCombinedCategory;
  currentDate: string;
}

export interface GetLegalAddressListInputDto {
  city: string;
}

export interface GetListOfHscodeInputDto {
  companyName: string;
  startDate: string;
  endDate: string;
}

export interface GetLogisticsFacilityListInputDto {
  city?: string | null;
  town?: string | null;
  categoryCodeList?: string[] | null;
  keyword?: string | null;
}

export interface GetMydashboardTemplateV2InputDto {
  orderByUser: number;
  type: TemplateRowType;
}

export interface GetNearPortsInput {
  date: string;
  port_code: string;
}

export interface GetNewsByPortInputDto {
  code: string;
  orderOption?: GnewsOrderOptionEnum | null;
  publishDateDayInterval?: number | null;
  take?: number | null;
}

export interface GetNewsTopicListInputDto {
  currentPage?: number | null;
  perPageTakeContent?: number | null;
  disPlayNewsCategory: NewsTopicCategory;
}

export interface GetOagChartInputDto {
  category: OagChartCategory;
  year: number;
  month: number;
}

export interface GetPaymentHistoryInputDto {
  skip: number;
  take: number;
}

export interface GetPaymentResultInputDto {
  transactionNo: string;
}

export interface GetPointListBySessionInputDto {
  currentPage: number;
  perPageTakeContent: number;
}

export interface GetPortCongestionInputDto {
  code: string;
  name: string;
}

export interface GetPostCustomDataByUserInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
}

export interface GetPostListByBusinessIdInputDto {
  id?: number | null;
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface GetPostListByLatestInputDto {
  limit?: number | null;
  mainCategoryList: MainCategoryEnum[];
  hashtagList: string[];
}

export interface GetPostListByPopularityInputDto {
  limit?: number | null;
  mainCategoryList: MainCategoryEnum[];
  hashtagList: string[];
  period: number;
}

export interface GetPostListByUserIdInputDto {
  id?: number | null;
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface GetPostListInputDto {
  mainCategory: MainCategoryEnum;
  hashtagList?: string[] | null;
  isWaitingForReply?: boolean | null;
  writerId?: number | null;
  currentPage?: number | null;
  perPageTakeContent?: number | null;
  keyword?: string | null;
  searchOption?: SearchOption | null;
  subject?: SubjectEnum | null;
  orderOption?: OrderOption | null;
}

export interface GetRankingForFameListInputputDto {
  from: any;
  to: any;
}

export interface GetRawMaterialToneListInputDto {
  category: IssueRawCombinedCategory;
  currentDate: string;
}

export interface GetRealTimeFlightScheduleInputDto {
  departureAndArrival: DepartureAndArrival;
}

export interface GetRegularFlightScheduleInputDto {
  departureAndArrival: DepartureAndArrival;
}

export interface GetReplyPostListInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
}

export interface GetScrappedPostListByLoginUserInputDto {
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface GetSubscriptionUsageInfoInputDto {
  email: string;
}

export interface GetSupplyChainCompanyListInputDto {
  year: string;
}

export interface GetSupplyChainHscodeForecastInputDto {
  hscode: string;
}

export interface GetSupplyChainLinkPredictionInputDto {
  companyName: string;
}

export interface GetSupplyChainSankeyInputDto {
  companyName: string;
  startDate: string;
  endDate: string;
}

export interface GetSupplyChainTreemapInputDto {
  companyName: string;
  startDate: string;
  endDate: string;
}

export interface GetSupplyRiskMonitorNewsTimelineInputDto {
  keyword: string;
  mainCategory: string;
  issueCase: string;
}

export interface GetSupplyRiskMonitorSummaryInputDto {
  keyword: string;
  res_numbers: number[];
}

export interface GetSupplyRiskMonitoringCountingInfoInputDto {
  keyword: string;
}

export interface GetSupplyRiskMonitoringIdentifyInputDto {
  keyword: string;
}

export interface GetUserByUserIdInputDto {
  userId: number;
}

export interface GetWeeklyReportListInputDto {
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface IncreasePostViewCountInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
}

export interface LarchiveProfileInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  user?: UserInputType | null;
  experience: number;
  level: number;
  scrapPostList?: ScrapPostInputType[] | null;
  likedPostList?: LikePostInputType[] | null;
  writtenPostList?: WrittenPostInputType[] | null;
  likeCompanyList?: string[] | null;
  likedCommentList?: string[] | null;
  likedPostAndCommentCountByOthers: number;
  reportPostCountByOthers: number;
  unreadMessageCount?: number | null;
  totalMessageCount?: number | null;
  totalCommentCount?: number | null;
}

export interface LikeCommentInputDto {
  _id: string;
  flag: boolean;
}

export interface LikePostInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
  flag: boolean;
}

export interface LikePostInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  subCategory?: SubCategoryEnum | null;
  mainCategory?: MainCategoryEnum | null;
  postId: string;
}

export interface LoginLocalAccountInputDto {
  email: string;
  password: string;
  skipOldUserCheck: boolean;
}

export interface LoginOldAccountAndCreateSimplogisUserInputDto {
  email: string;
  whichSns: Sns;
  password?: string | null;
}

export interface LogisticBulkInputDto {
  tableNum: LogisticTableList;
  periodNumber: number;
}

export interface NearPortsWithGeoInfoInput {
  date: string;
  port_code: string;
}

export interface NewsByTopicInput {
  categoires: string[];
  interval: string;
}

export interface PeriodLimitTypeInputType {
  period: number;
  period_unit: PERIOD_UNIT_TYPE;
}

export interface PinMessageInputDto {
  messageType: MessageType;
  messageId: number;
  isPinned: boolean;
}

export interface PinScrapPostInputDto {
  postId: string;
  isPinned: boolean;
}

export interface RefundSubscriptionPaymentInputDto {
  reason: string;
}

export interface ReportPostInputDto {
  reportType: ReportPostType;
  reportContent: string;
  mainCategory: MainCategoryEnum;
  postId: string;
}

export interface ResetPasswordInputDtoType {
  password: string;
  email: string;
  authCode: string;
}

export interface ScrapPostInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
}

export interface ScrapPostInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  subCategory?: SubCategoryEnum | null;
  mainCategory?: MainCategoryEnum | null;
  postId: string;
  isPinned: boolean;
}

export interface SearchCargoSafetyFareInputDto {
  applicatedYear: number;
  applicatedQuarter: string;
  portName: PortName;
  city?: string | null;
  town?: string | null;
  village?: string | null;
  extraChargePercentList?: ExtraChargePercent[] | null;
  cargoSafetyFareSystemCategory: CargoSafetyFareSystemCategory;
}

export interface SearchOceanFreightAnnouncementInputDto {
  codPrtCd: string;
  shipngPrtCd: string;
  startEffectiveDate: any;
  endEffectiveDate: any;
}

export interface SendCargoEstimatesInputDto {
  WhoInCharge: string;
  phoneNumber: string;
  email: string;
  itemExplanation: string;
  tempType: TemperatureEnum[];
  quantity: number;
  period: number;
  extraExplanation: string;
  attachments?: AttachmentInputType[] | null;
}

export interface SendEmailResetPasswordInputDto {
  email: string;
}

export interface SendMessageInputDto {
  content: string;
  receiverId: number;
}

export interface ShowProfileInputDto {
  unreadMessageCountRequired?: boolean | null;
}

/**
 * 단일 지수 호출용 객체
 */
export interface SingleBiInputType {
  chartNum?: number | null;
  biName: string;
  biId: number;
  pickedCategoryId?: string | null;
  biRouteWithCategoryList: BiRouteWithCategoryInputType[];
}

export interface SingleBiRouteWithCategoryInputType {
  biCategoryId?: string | null;
  biRoute: number;
}

/**
 * 단일 지수 호출용 객체
 */
export interface SingleBiV2InputType {
  chartNum?: number | null;
  coordinate: ChartCoordinateInputType;
  biName: string;
  biId: number;
  pickedCategoryId?: string | null;
  biRouteWithCategoryList: BiRouteWithCategoryInputType[];
}

export interface SingleBiWithoutChartNumInputType {
  biName: string;
  biId: number;
  pickedCategoryId?: string | null;
  biRouteWithCategoryList: BiRouteWithCategoryInputType[];
}

export interface SoftDeleteProfileAndUserInputDto {
  passcode: string;
}

export interface SubscriptionContentInputType2 {
  weeklyReport?: VersionLimitTypeInputType | null;
  mydashboardAlarm?: CntLimitTypeInputType | null;
  mydashboardTemplate?: CntLimitTypeInputType | null;
  dataService?: PeriodLimitTypeInputType | null;
  portCongestion?: ApiCallLimitTypeInputType | null;
  apiLinking?: BooleanLimitTypeInputType | null;
  additionalAccountRight?: BooleanLimitTypeInputType | null;
}

export interface SwitchBusinessAccountFromPersonalInputDto {
  businessName: string;
  businessRegisterNo: string;
}

export interface TemplateInformationInput {
  compositeBiList?: SingleBiInputType[] | null;
  comparisonBiList?: ComparisonBiInfoInputType[] | null;
}

export interface TemplateInformationInputV2 {
  compositeBiList?: SingleBiV2InputType[] | null;
  comparisonBiList?: ComparisonBiInfoV2InputType[] | null;
  anomalyBiInfoList?: AnomalyBiInfoV2InputType[] | null;
}

export interface UnScrapPostInputDto {
  _id: string;
}

export interface UpdateBookmarkListByLoginUserInputDto {
  newBookmarkList: string[];
}

export interface UpdateBusinessRegistrationPaperInputDto {
  business_registration_paper_url?: string | null;
}

export interface UpdateMydashboardAlarmInputDto {
  id?: number | null;
  changeRate: number;
}

export interface UpdateMydashboardTemplateNameInputDto {
  name?: string | null;
  templateId: number;
}

export interface UpdateNicknameInputDto {
  nickname: string;
}

export interface UpdateTelInputDto {
  tel?: string | null;
}

export interface UpdateUserPasswordInMypageInputDto {
  email: string;
  newPassword: string;
}

export interface UpdateUserTypeInputDto {
  userType?: IndustryFieldList | null;
}

export interface UpsertAndDeleteMydashboardTemplateSeparatelyTemplateInformationV2InputDto {
  templateId?: number | null;
  orderByUser: number;
  type: TemplateRowType;
  willUpsertTemplateInformation?: TemplateInformationInputV2 | null;
}

export interface UpsertBookmarkInputDto {
  id?: number | null;
  biList?: BiInfoInputType[] | null;
}

export interface UpsertDashboardWidgetByLoginUserInputDto {
  widget_list?: Widget[] | null;
}

export interface UpsertMydashboardTemplateInputDto {
  name?: string | null;
  templateInformation: TemplateInformationInput;
  orderByUser: number;
  templateId?: number | null;
}

export interface UseLarchiveFromOtherServiceForLocalInputDto {
  email: string;
  password: string;
}

export interface UseLarchiveFromOtherServiceForSnsInputDto {
  email: string;
  whichSns: Sns;
}

export interface UserInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dashboardBookmarkList?: string[] | null;
  widget_list?: Widget[] | null;
  email: string;
  nickname: string;
  nicknameUpdatedAt: any;
  outputRefCode: string;
  inputRefCode?: string | null;
  tel?: string | null;
  isBusinessAccount: boolean;
  agreementTermsConditionsPersonalInformationAt: any;
  agreementReceivingEventsAndMarketingAt?: any | null;
  userType?: IndustryFieldList | null;
  lastLoginAt?: any | null;
  whichSns: Sns;
  profilePhoto?: string | null;
  interest?: string[] | null;
  introduction?: string | null;
  connectedBusiness?: BusinessInputType | null;
  managedBusiness?: BusinessInputType | null;
  userAccountStatus: boolean;
  emailVerified: boolean;
  larchiveProfile: LarchiveProfileInputType;
  userId?: number | null;
  deletedAt?: any | null;
  memoByAdmin?: string | null;
  adminRoleList?: AdminRole[] | null;
  adminName?: string | null;
  careerList?: CareerInputType[] | null;
  careerPeriod?: number | null;
  educationList?: EducationInputType[] | null;
  isSpecialist: boolean;
  addedSpecialistRoleAt?: any | null;
  subscription?: subscriptionInputType | null;
  initialPaymentStatus?: boolean | null;
}

export interface VersionLimitTypeInputType {
  version: VERSION_TYPE;
}

export interface ViewBusinessInfoInputDto {
  id?: number | null;
  advertisementType?: AdType | null;
}

export interface ViewMessageInputDto {
  id?: number | null;
  isReceivedMessage: boolean;
}

export interface ViewMessageListInputDto {
  messageType: MessageType;
  currentPage?: number | null;
  perPageTakeContent?: number | null;
}

export interface ViewPostInputDto {
  _id: string;
  mainCategory: MainCategoryEnum;
}

export interface WrittenPostInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  subCategory?: SubCategoryEnum | null;
  mainCategory: MainCategoryEnum;
  postId: string;
  deletedAt?: any | null;
}

export interface subscriptionInputType {
  id?: number | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  productAlias: SubscriptionType;
  productId: number;
  content: SubscriptionContentInputType2;
  expiredAt?: any | null;
  user?: UserInputType | null;
  transactionNo?: string | null;
  subCategory: PRODUCT_SUBCATEGORY_ENUM;
  flagForHistoryOfPaidPayment: boolean;
  isLastFlag?: boolean | null;
  numberOfMember?: number | null;
  issuedByAdmin?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
