import React, { ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class LocalErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    console.log(error);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) {
      // todo : error 일때 rendering할 component생성
      // todo : chunk load -> refresh , another error check -> errorPage?
      console.log(hasError);
      return null;
    }

    const { children } = this.props;
    return children;
  }
}

export default LocalErrorBoundary;
