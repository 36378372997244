interface GTagEvent {
  action: string;
  category?: string;
  label?: string;
  value?: number;
}

interface adClickEventType {
  adtype: string;
  usertype: string;
  path: string;
  business?: string;
}

export const pageview = (url: URL) => {
  window.gtag('config', `${process.env.REACT_APP_GOOGLE_ANALTICS}`, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value }: GTagEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const adClickEvent = ({ adtype, usertype, path, business }: adClickEventType) => {
  window.gtag('event', '광고클릭', {
    adtype,
    usertype,
    path,
    business,
  });
};

interface pageViewEventParams {
  page: string;
  userId?: number | null;
  userEmail: string;
}

// export const pageViewEventForId = ({ page, userId, userEmail }: pageViewEventParams) => {
//   window.gtag('event', userId, {
//     page,
//     userEmail,
//   });
// };

export const pageViewEventForPage = ({ page, userId, userEmail }: pageViewEventParams) => {
  window.gtag('event', page, {
    ...(userId && { userId }),
    userEmail,
  });
};
