import { CameraIconComponent, defaultProfile } from 'assets/image';

import styled from 'styled-components';
import { motion } from 'framer-motion';

import useMypageHeader from 'views/MyPage/hooks/useMypageHeader';

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  /* background-color: ${props => props.theme.colors.grayBackgroundColor}; */
  /* @media screen and (max-width: 500px) {
    padding: 20px 0px;
  } */
`;

const Container = styled.section`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* @media screen and (max-width: 500px) {
    width: 60px;
    height: 60px;
  } */
`;

interface ImageLabelProps {
  bgimage: string;
}

const ImageLabel = styled(motion.label)<ImageLabelProps>`
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 50%;

  background: ${({ bgimage }) => `url(${bgimage}) no-repeat`};
  background-position: center;
  background-size: cover;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageHoverContainer = styled(motion.div)`
  width: 180px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  position: absolute;
  z-index: 5;
  bottom: 0px;
  border-radius: 0 0 180px 180px;
  transform-origin: bottom;
  img {
    width: 30px;
    height: 30px;
  }
  /* @media screen and (max-width: 500px) {
    width: 60px;
    height: 30px;
    img {
      width: 15px;
      height: 15px;
    }
  } */
`;

const UserNameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  b {
    color: ${props => props.theme.colors.baseColor};
  }
`;

const CameraIcon = styled(CameraIconComponent)`
  width: 32px;
  stroke: #fff;
`;

const LabelVariants = {
  rest: {},
  hover: {
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const HoverVariants = {
  rest: { opacity: 0, ease: 'easeOut', duration: 0.2, type: 'tween' },
  hover: {
    y: 1,
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

function MypageHeader() {
  const { userInfo, editProfilePhoto } = useMypageHeader();

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <ImageInput id="profile_image_upload" type="file" onChange={editProfilePhoto} />
          <ImageLabel
            variants={LabelVariants}
            initial="rest"
            animate="rest"
            whileHover="hover"
            htmlFor="profile_image_upload"
            bgimage={userInfo?.avartar || defaultProfile}
          >
            <ImageHoverContainer variants={HoverVariants}>
              <CameraIcon />
            </ImageHoverContainer>
          </ImageLabel>
        </ImageContainer>

        <UserNameBox>
          <b>{`${userInfo?.name || ''}`}</b>님, 환영합니다!
        </UserNameBox>
      </Container>
    </Wrapper>
  );
}

export default MypageHeader;
