import { FirebaseError } from 'firebase/app';
import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  User,
} from 'firebase/auth';

import { auth } from 'lib/firebase/firebase';

import authError from './authErrors';

function mainAuth() {
  // * logout
  const authLogout = async () => {
    await auth.signOut();
  };
  // * login auth
  const getLoginAuth = async (token: string) => {
    try {
      const { user } = await signInWithCustomToken(auth, token);

      await setPersistence(auth, browserLocalPersistence);

      const firebaseIdToken = await user.getIdToken(true);

      if (firebaseIdToken === undefined) {
        throw new Error('get token failed');
      }
      return firebaseIdToken;
    } catch (err) {
      if (err instanceof FirebaseError) {
        authError(err, authLogout);
      }
    }
  };

  // * state change
  const getAccessToken = async (isForce = false) => {
    return new Promise(function (resolve) {
      onAuthStateChanged(auth, async (user: User | null) => {
        try {
          // !! 차후 문제 있을시 auth.currentUser 로 변경 TEST
          if (!user) {
            return resolve(undefined);
          }

          const token = (await user?.getIdTokenResult(isForce))?.token;
          resolve(token);
        } catch (err) {
          if (err instanceof FirebaseError) {
            authError(err, authLogout);
          }
        }
      });
    });
  };

  const authStateManage = {
    getLoginAuth,
    getAccessToken,
    authLogout,
  };

  return { authStateManage };
}

export default mainAuth;
