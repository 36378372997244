import styled from 'styled-components';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface SweetAlertProps {
  icon: SweetAlertIcon;
  title: string;
  showDeny?: boolean;
  confirmButtonText?: string;
  description?: JSX.Element | string;
  input?: string;
  inputOptions?: string[] | { [key: string]: string };
  inputValidator?: any;
  allowOutsideClick?: boolean;
  allowEnterKey?: boolean;
  denyButtonText?: string;
  position?: SweetAlertPosition;
  width?: string;
}

const SweetAlertTitle = styled.div`
  font-size: 20px;
  line-height: 1.5;
  color: #333333;
  word-break: keep-all;
`;

const SweetAlertDescription = styled.div`
  font-size: 18px;
  line-height: 1.4;
  color: #666666;
  word-break: keep-all;
`;

const SweetAlertDenyButtonText = styled.div`
  color: #333333;
`;

function sweetAlert({
  icon,
  title,
  description,
  confirmButtonText,
  inputOptions,
  input,
  inputValidator,
  allowOutsideClick = false,
  allowEnterKey = false,
  showDeny = false,
  denyButtonText = '취소',
  position,
  width = '480px',
}: SweetAlertProps) {
  const alert = withReactContent(Swal);
  return alert.fire({
    icon,
    title: <SweetAlertTitle>{title}</SweetAlertTitle>,
    ...(description && { html: <SweetAlertDescription>{description}</SweetAlertDescription> }),
    width,
    confirmButtonColor: '#45b480',
    confirmButtonText: confirmButtonText || '확인',
    showDenyButton: showDeny,
    denyButtonColor: '#dddddd',
    denyButtonText: <SweetAlertDenyButtonText>{denyButtonText}</SweetAlertDenyButtonText>,
    ...(input && { input: 'select' }),
    inputOptions,
    inputValidator,
    allowOutsideClick,
    allowEnterKey,
    ...(position && { position }),
  });
}

export default sweetAlert;
