import styled from 'styled-components';

import { useReactiveVar } from '@apollo/client';
import { SubscriptionType } from '__apollo__generated__/globalTypes';

import { userProfileVar } from 'store/UserStore';
import { useEffect, useMemo, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import usePageHistory from 'hooks/Common/usePageHistory';
import { lockIcon } from 'assets/image';

export default function TrialProfile() {
  const { history } = usePageHistory();
  const profile = useReactiveVar(userProfileVar);

  // const [date, setDate] = useState(0);
  const [timer, setTimer] = useState(0);
  // const [remainDay, setRemainDay] = useState(0);

  // const remainDay = useMemo(() => {
  //   if (profile?.subscription?.productAlias === SubscriptionType.TRIAL_STANDARD) {
  //     const startDate = DateTime.now();
  //     const endDate = DateTime.fromISO(profile?.subscription?.expiredAt);

  //     if (startDate && endDate) {
  //       const diff = endDate.diff(startDate, 'hours').hours;

  //       if (diff < 24) {
  //         setTimer(Math.floor(diff * 60 * 60));
  //       }

  //       return Math.floor(diff) || 0;
  //     }
  //   }

  //   return -1;
  // }, [profile, setTimer]);

  useEffect(() => {
    if (profile?.subscription?.productAlias === SubscriptionType.TRIAL_STANDARD) {
      const startDate = DateTime.now();
      const endDate = DateTime.fromISO(profile?.subscription?.expiredAt);

      if (startDate && endDate) {
        const diff = endDate.diff(startDate, 'minutes').minutes;

        if (diff <= 1) {
          setTimer(-1);
        } else {
          setTimer(Math.floor(diff));
        }

        // if (diff < 24) {
        // }
        // setTimer()
        // return Math.floor(diff) || 0;
      }
      // setTimer(-1);
    }
  }, [profile, setTimer]);

  useEffect(() => {
    // setInterval을 이용해서 가입시점 + 10분과 현재의 시간 gap을 저장
    // if (remainDay < 24) {

    if (timer < 1) {
      return setTimer(-1);
    }

    setTimeout(() => {
      setTimer(timer - 1);
    }, 60000);
    // }
  }, [setTimer, timer]);

  const tfhourTime = useMemo(() => {
    const duration = Duration.fromObject({ minutes: timer }).shiftTo('days', 'hours', 'minutes', 'seconds');
    // 일, 시간, 분, 초로 변환합니다.
    const days = duration.days;
    const hours = duration.hours;
    const minutes = duration.minutes;
    // const seconds = duration.seconds;

    if (days >= 7) {
      return `${days} 일 ${hours} 시간`;
    } else {
      return `${days} 일 ${hours} 시간 ${minutes} 분`;
    }
  }, [timer]);

  const _handleClickUpgrade = () => {
    history.push('/plan/explain');
  };

  return (
    <div className="flex items-center gap-3">
      {profile?.subscription?.productAlias === SubscriptionType.FREE && (
        <div className="font-semibold text-center text-white">무료 서비스를 이용중입니다</div>
      )}
      {profile?.subscription?.productAlias === SubscriptionType.TRIAL_STANDARD && (
        <div className="font-semibold text-center text-white">Trial Standard</div>
      )}
      <div className="relative">
        <ProgressGauge value={timer > 1 ? timer : 0} max={24 * 60 * 7} />
        <div className="text-center absolute text-[11px] whitespace-nowrap text-white -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {timer < 0 ? (
            <div className="flex items-center gap-2 ">
              <img src={lockIcon} alt="" className="w-4" /> 기간 만료
            </div>
          ) : (
            `${tfhourTime}`
          )}

          {/* // remainDay < 24 ? (
          //   `Trial이 곧 만료됩니다.`
          // ) : (
          //   `남은기간: ${Math.ceil(remainDay / 24) > 7 ? 7 : Math.ceil(remainDay / 24)} 일`
          // )} */}
        </div>
      </div>

      <div
        onClick={_handleClickUpgrade}
        className="px-[8px] py-[6px] text-white bg-baseColor whitespace-nowrap rounded-[4px] text-[14px] cursor-pointer shadow-whiteBoxShadow"
      >
        Plan 업그레이드
      </div>
    </div>
  );
}

const ProgressGauge = styled.progress`
  width: 140px;
  height: 20px;
  appearance: none;
  &::-webkit-progress-bar {
    background: #000000b8;
    border-radius: 6px;
  }
  &::-webkit-progress-value {
    background: linear-gradient(90deg, rgba(58, 148, 180, 1) 0%, rgba(51, 202, 112, 1) 100%);
    border-radius: 6px;
  }
`;
