import Cookies from 'universal-cookie';

import { FirebaseError } from 'firebase/app';

import { client, isLoggedInVar } from '../lib/apollo/apollo';

export default function authError(err: FirebaseError, authLogout: () => void) {
  const cookies = new Cookies();

  switch (err.code) {
    case 'auth/user-token-expired':
      console.log(err);
      isLoggedInVar(false);
      client.clearStore();
      cookies.remove('firebase-id-token', {
        path: '/',
      });
      authLogout();
      window.location.replace('/');
      window.location.reload();
      return;
    case '400':
      console.log(err);
      isLoggedInVar(false);
      client.clearStore();
      cookies.remove('firebase-id-token', {
        path: '/',
      });
      authLogout();
      window.location.replace('/');
      window.location.reload();
      return;
    default:
      console.log(err);
      isLoggedInVar(false);
      client.clearStore();
      cookies.remove('firebase-id-token', {
        path: '/',
      });
      authLogout();
      window.location.replace('/');
      return;
  }
}
