import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Fragment, Suspense, useEffect, useState } from 'react';

import Member from 'views/Member';
import useWebMainLayout from 'components/layouts/hooks/Controller/useWebMainLayout';

import { getClass } from 'util/tailwind';

import WebNavigation from 'components/layouts/WebNavigation';
import WebHomeFooter from './WebHomeFooter';
import WebFooter from 'components/layouts/WebFooter';
import LogisticNavigationAdaptive from './LogisticNavigationAdaptive';
import styled from 'styled-components';
import CookieConsent from 'components/CookieConsent';
import { getCookie } from 'util/cookies';

// import { isNavOpen } from 'store/navigationState';
import useMemoCookies from 'hooks/Common/useMemoCookies';

import { PaymentNoticePop } from './Notice';
// import sweetAlert from 'components/Alert/sweetAlert';
// import { DateTime } from 'luxon';
// import sweetAlert from 'components/Alert/sweetAlert';
// import { DateTime } from 'luxon';

const Wrapper = styled.div``;

interface WebMainLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

const NO_SHOW_RELEASE_FREE_NOTICE_COOKIE = 'release-notice-payment-free-pop-close';

// const NO_SHOW_SERVICE_POP = 'service-notice-pop-202401021';

function WebMainLayout({ children }: WebMainLayoutProps) {
  const [showCookieConsented, setShowCookieConsented] = useState(false);
  const { showLoginVar, topClick, pathName } = useWebMainLayout();
  const memoCookies = useMemoCookies();

  const [showPaymentNotice, setShowPaymentNotice] = useState(false);

  useEffect(() => {
    const hasConsent = getCookie('cookie-consent-agree');

    if (hasConsent) {
      setShowCookieConsented(false);
    } else {
      setShowCookieConsented(true);
    }
  }, [setShowCookieConsented, pathName]);

  useEffect(() => {
    memoCookies.remove('congestion-apology-popup-hidden', {
      path: '/',
    });
    const notShow = memoCookies.get(NO_SHOW_RELEASE_FREE_NOTICE_COOKIE);
    if (notShow || pathName.includes('plan')) {
      setShowPaymentNotice(false);
    } else {
      setShowPaymentNotice(true);
    }
  }, [memoCookies, setShowPaymentNotice, pathName]);

  // useEffect(() => {
  //   sweetAlert({
  //     icon: 'info',
  //     title: '[서비스 점검] 서비스 점검 안내',
  //     description: (
  //       <div>
  //         <div>금일 19시부터 21시까지</div>
  //         <div>서비스 점검으로 이용이 원활하지 않을 수 있습니다.</div>
  //         <div>이용에 불편을 드려 대단히 죄송합니다.</div>
  //         <div>감사합니다.</div>
  //       </div>
  //     ),
  //   });
  // }, []);

  // useEffect(() => {
  //   const has = getCookie(NO_SHOW_SERVICE_POP);
  //   if (has) return;

  //   sweetAlert({
  //     icon: 'info',
  //     title: '[공지] 팀 결제 업데이트 지연 안내',
  //     width: '640px',
  //     description: (
  //       <div className="text-[17px] flex flex-col gap-1 text-black">
  //         <div>팀 결제 배포 중 결제 서비스 사에서 </div>
  //         <div>에러를 발견하여 서비스 출시가 지연되었습니다.</div>
  //         <div>불편을 끼쳐드려 고객님들께 깊은 사과를 드립니다.</div>
  //         <div>빠른 시일 내에 팀 결제 서비스를 제공할 수 있도록 하겠습니다.</div>
  //         <div>감사합니다.</div>
  //         <div className="flex items-center justify-center gap-5">
  //           <div
  //             // onClick={() => {
  //             //   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //             //     maxAge: 24 * 60 * 60,
  //             //   });
  //             // }}

  //             className="flex justify-center gap-2 mt-5 cursor-pointer"
  //           >
  //             <input
  //               onChange={(e: ChangeEvent<HTMLInputElement>) => {
  //                 const {
  //                   currentTarget: { checked },
  //                 } = e;

  //                 if (checked) {
  //                   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //                     maxAge: 24 * 60 * 60 * 7,
  //                     path: '/',
  //                   });
  //                 } else {
  //                   memoCookies.remove(NO_SHOW_SERVICE_POP, {
  //                     path: '/',
  //                   });
  //                 }
  //               }}
  //               id={NO_SHOW_SERVICE_POP + '1'}
  //               type="checkbox"
  //               className="w-[20px] aspect-square"
  //             />
  //             <label className="mt-1 cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '1'}>
  //               다시 보지않기
  //             </label>
  //           </div>
  //           {/* <div
  //             onClick={() => {
  //               memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //                 maxAge: 24 * 60 * 60 * 7,
  //               });
  //             }}
  //             className="flex justify-center gap-2 mt-5 cursor-pointer"
  //           >
  //             <input id={NO_SHOW_SERVICE_POP + '7'} type="checkbox" />
  //             <label className="cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '7'}>
  //               일주일동안 보지 않기
  //             </label>
  //           </div> */}
  //         </div>
  //       </div>
  //     ),
  //   });
  // }, [memoCookies, pathName]);

  return (
    <Wrapper className={getClass('min-w-[1600px] w-full ')}>
      <main className="min-h-[60vh] relative">
        {showPaymentNotice && (
          <PaymentNoticePop cookieName={NO_SHOW_RELEASE_FREE_NOTICE_COOKIE} close={setShowPaymentNotice} />
        )}
        <WebNavigation />
        <div className="flex">
          {pathName.includes('logistic-tools') && <LogisticNavigationAdaptive />}
          {children}
        </div>
        {showCookieConsented && <CookieConsent setShowCookieConsented={setShowCookieConsented} />}
      </main>
      {showLoginVar && (
        <Suspense fallback={<Fragment></Fragment>}>
          <Member />
        </Suspense>
      )}

      {pathName === '/' ? <WebHomeFooter /> : <WebFooter />}
      <div className="w-[50px] h-[50px] fixed bottom-[100px] right-[30px]">
        <div
          onClick={topClick}
          className="w-full h-full bg-white cursor-pointer rounded-2xl
            bg-[rgba(0,0,0,0.4)]
            opacity-20
            hover:opacity-100
            text-navyColor
            flex
            justify-center
            items-center
          "
        >
          Top
        </div>
      </div>
    </Wrapper>
  );
}

export default withRouter(WebMainLayout);
