interface PasswordPattern {
  id: string;
  name: string;
  pattern: RegExp;
}

export const passwordPatterns: PasswordPattern[] = [
  {
    id: 'eng',
    name: '영문',
    pattern: /[a-zA-Z]/,
  },
  {
    id: 'num',
    name: '숫자',
    pattern: /[0-9]/,
  },
  {
    id: 'symbol',
    name: '특수문자',
    pattern: /[~!@#$%<>^&*]/,
  },
  {
    id: 'length',
    name: '8자 이상',
    pattern: /.{8,}$/,
  },
];
