import { useEffect } from 'react';

import MainRouter from 'router/MainRouter';

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  if (process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  return <MainRouter />;
}

export default App;
