import { DateTime } from 'luxon';
import { useMemo } from 'react';

function useGetDate(format = 'yyyy-MM-dd') {
  const date = new Date();
  const memoDate = useMemo(() => {
    return new Date();
  }, []);
  const year = date.getFullYear();
  const today = date.getDate();
  const month = date.getMonth() + 1;
  const formattingDate = DateTime.fromJSDate(date).toFormat(format);
  return { date, today, month, year, formattingDate, memoDate };
}

export default useGetDate;
