import { gql } from '@apollo/client';

export const ACTIVE_DORMANT_ACCOUNT = gql`
  mutation Mut_Active_Dormant_Account($input: ActivateDormantAccountInputDto!) {
    activateDormantAccount(input: $input) {
      ok
      error {
        code
        message
      }
      customToken
    }
  }
`;
