import { gql } from '@apollo/client';

export const GET_LARCHIVE_TOKEN = gql`
  mutation Mut_Get_Larchive_Token {
    getLarchiveToken {
      error
      ok
      larchiveToken
    }
  }
`;
