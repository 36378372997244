import { useMemo } from 'react';
import usePageHistory from './usePageHistory';

function useMemoParams() {
  const { pageQuery, history, pathName } = usePageHistory();

  const memoParams = useMemo(() => {
    const params = new URLSearchParams(pageQuery);
    return params;
  }, [pageQuery]);

  return { memoParams, history, pathName };
}

export default useMemoParams;
