import { setCookie } from 'util/cookies';

import { SetStateAction, Dispatch } from 'react';

export default function CookieConsent({
  setShowCookieConsented,
}: {
  setShowCookieConsented: Dispatch<SetStateAction<boolean>>;
}) {
  const giveCookieConsent = () => {
    setCookie('cookie-consent-agree', true, {
      path: '/',
      maxAge: 31557600,
      sameSite: 'lax',
    });
    setShowCookieConsented(false);
  };

  const closeConsent = () => {
    setShowCookieConsented(false);
  };

  return (
    <div className="w-full max-w-[1200px] h-fit flex justify-between items-center gap-20 fixed inset-x-2/4 bottom-4 -translate-x-2/4 z-[999] py-6 px-4 bg-white rounded shadow-baseShadow">
      <div className="flex flex-col gap-2 leading-snug break-keep">
        <p>
          사용자가 쿠키 저장을 허용하도록 웹 브라우저를 설정하고 당사 웹사이트를 방문함으로써 사용자는 당사 및 당사
          서비스 제공자가 이 웹사이트에서 쿠키를 사용하는 것에 동의하는 것으로 간주됩니다.
        </p>
        <p>
          만약 사용자가 쿠키 저장을 원치 않을 경우 사용자는 웹 브라우저의 설정을 변경하여 쿠키 사용을 거부할 수
          있습니다.
        </p>
        <p>
          웹 브라우저 설정을 통해 모든 쿠키를 허용하거나, 일부 쿠키만 허용 하거나, 쿠키 저장 시 확인을 하거나, 모든
          쿠키의 저장을 거부할 수 있습니다.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <button onClick={giveCookieConsent} className="w-20 px-4 py-2 text-base text-white rounded bg-baseColor">
          동의
        </button>
        <button onClick={closeConsent} className="w-20 px-4 py-2 text-base text-white rounded bg-slate-200">
          미동의
        </button>
      </div>
    </div>
  );
}
