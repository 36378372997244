import { ReactNode } from 'react';
import styled from 'styled-components';
import MypageHeader from '../MypageHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 0px 0px 60px 0px;
`;

interface MainLayoutProps extends RouteComponentProps {
  children?: ReactNode;
  props?: any;
}

function MypageMainLayout({ children }: MainLayoutProps) {
  return (
    <Wrapper>
      <MypageHeader />
      {children}
    </Wrapper>
  );
}

export default withRouter(MypageMainLayout);
