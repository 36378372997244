import { DateTime } from 'luxon';
import { ReactElement } from 'react';

interface CommonErrorDescriptionProps {
  informationElement?: ReactElement;
  isError?: boolean;
  formalText?: string;
}

// * 해당 컴포넌트는 error발생시 원인이 바로 나타나지 않은 경우 사용하는 컴포넌트입니다.
// * 전달되는 prop은 information element로 html text로 보내주시면 됩니다.
export default function CommonErrorDescription({
  informationElement,
  isError,
  formalText = '자세한 사항은',
}: CommonErrorDescriptionProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-2 leading-6 break-keep">
      {informationElement}

      {isError && (
        <div className="flex flex-col gap-[2px] items-center justify-center w-full ">
          <div className="flex items-center justify-center gap-[2px]">
            {formalText} <div className="underline text-main-color underline-offset-2">help@simplogis.com</div> 또는
          </div>
          <div className="flex items-center justify-center gap-[2px]">
            하단의 <div className="underline text-main-color underline-offset-2">채널톡</div> 으로 으로 문의 바랍니다.
          </div>
        </div>
      )}

      {isError && <div className="font-bold">발생시간 : {DateTime.local().toFormat('yyyy/MM/dd HH:mm:ss')}</div>}
    </div>
  );
}
