import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { SyntheticEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';

import useMemoParams from 'hooks/Common/useParams';

import Button from 'components/Common/Button';
import AnimatedCheckIcon from 'components/SocialLogin/AnimatedCheckIcon';
import sweetAlert from 'components/Alert/sweetAlert';

import { RESET_PASSWORD_IN_CUSTOM_PASSWORD_PAGE } from 'graphql/Login/resetPasswordInCustomPasswordPage';
import {
  Mut_Reset_Password_In_Custom_Password_Page,
  Mut_Reset_Password_In_Custom_Password_PageVariables,
} from '__apollo__generated__/Mut_Reset_Password_In_Custom_Password_Page';

import { REGEX_PASSWORD } from 'util/constantRegex';
import { passwordPatterns } from 'views/Member/SignUp/util';
import { showLogin } from 'store/Member';
import { DefaultColorLogo } from 'assets/image';

const Wrapper = styled.div`
  min-height: 70vh;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 370px;
  height: 550px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  box-shadow: ${props => props.theme.boxShadow.baseShadow};
  padding: 30px;
`;

const Content = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const TextContent = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.buttontextNavyColor};
`;

const SubmitContent = styled.div`
  max-width: 308px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface InputProps {
  isBlank: boolean;
}

const Input = styled.div<InputProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 6px;
  transition: all 200ms ease-in;

  &:focus-within {
    border: 1.5px solid ${({ theme }) => theme.colors.headerBacgroundColor};
  }

  label {
    position: absolute;
    top: 15px;
    left: 18px;
    z-index: 1;
    color: #ddd;
    transition: all 250ms ease-in-out;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  ${({ isBlank }) =>
    isBlank &&
    css`
      label {
        font-size: 12px;
        top: -5px;
        left: 14px;
        padding: 1px 6px;
        background-color: ${({ theme }) => theme.colors.backgroundColor};
        color: ${({ theme }) => theme.colors.subFontColor};
        pointer-events: none;
      }
    `}

  input:focus + label {
    font-size: 12px;
    top: -5px;
    left: 14px;
    padding: 1px 6px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.headerBacgroundColor};
    pointer-events: none;
  }
`;

const ValidCheck = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 1px;
  margin-bottom: 10px;
`;

interface IValidationItem {
  isConfirmed: boolean;
}

const ValidCheckItem = styled.div<IValidationItem>`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  color: ${({ isConfirmed }) => (isConfirmed ? '#4be17c' : '#dddddd')};
  transition: color 200ms ease-in;
`;

const SubTitle = styled.div`
  margin-top: 30px;
`;

function ResetPassword() {
  const { memoParams } = useMemoParams();

  const [urlParams, setUrlParams] = useState({
    email: '',
    code: '',
    expired: '',
  });

  useEffect(() => {
    const email = memoParams.get('email');
    const code = memoParams.get('code');
    const expired = memoParams.get('expired');
    if (email && code && expired) {
      setUrlParams({ email, code, expired });
    }
  }, [memoParams, setUrlParams]);

  const [resetPasswordMut] = useMutation<
    Mut_Reset_Password_In_Custom_Password_Page,
    Mut_Reset_Password_In_Custom_Password_PageVariables
  >(RESET_PASSWORD_IN_CUSTOM_PASSWORD_PAGE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: Mut_Reset_Password_In_Custom_Password_Page) => {
      const {
        resetPasswordInCustomPasswordPage: { ok, error },
      } = data;
      if (!ok || error) {
        return sweetAlert({
          icon: 'info',
          title: error?.message || '비밀번호 재설정에 실패했습니다',
          description: (
            <div>
              <div>help@simplogis.com</div>
              <div>으로 문의 바랍니다.</div>
              <div style={{ fontWeight: 'bold' }}>발생경로 : 비밀번호재설정, {urlParams.email}</div>
              <div style={{ fontWeight: 'bold' }}>
                발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd hh:mm:ss')}
              </div>
            </div>
          ),
        }).then(() => {
          window.close();
          window.location.replace('/');
          setTimeout(() => {
            showLogin(true);
          }, 300);
        });
      }
      return sweetAlert({
        icon: 'success',
        title: '비밀번호가 재설정되었습니다',
        description: <div>다시 로그인해주세요</div>,
      }).then(() => {
        window.close();
        window.location.replace('/');
        setTimeout(() => {
          showLogin(true);
        }, 300);
      });
    },
  });

  const [resetPassword, setResetPassword] = useState('');

  const passwordOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    setResetPassword(value);
  };

  const resetPasswordClick = async () => {
    await resetPasswordMut({
      variables: {
        input: {
          email: urlParams.email,
          password: resetPassword,
          authCode: urlParams.code,
        },
      },
    });
  };

  const closeBtnClick = () => {
    window.close();
    window.location.replace('/');
    setTimeout(() => {
      showLogin(true);
    }, 300);
  };

  return (
    <Wrapper>
      <Container>
        <Content
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
        >
          <div>
            <img src={DefaultColorLogo} alt="color-logo" />
          </div>
          <Title>비밀번호 변경</Title>
          <TextContent>
            비밀번호는 영문, 숫자, 특수문자를 <br /> 포함한 8자 이상으로 만들어주세요.
          </TextContent>
          {urlParams.expired === 'false' ? (
            <SubmitContent>
              <SubTitle>변경하실 비밀번호를 입력해주세요</SubTitle>
              <Input isBlank={resetPassword.length !== 0}>
                <input required type="password" onChange={passwordOnChange} value={resetPassword} />
                <label htmlFor="password">비밀번호</label>
              </Input>
              <ValidCheck>
                {passwordPatterns.map(item => {
                  const isValid = item.pattern.test(resetPassword);
                  return (
                    <ValidCheckItem key={item.id} isConfirmed={isValid ?? false}>
                      <span>{item.name}</span>
                      <AnimatedCheckIcon isVisible={isValid} />
                    </ValidCheckItem>
                  );
                })}
              </ValidCheck>
              <Button
                onClick={resetPasswordClick}
                btnBg={REGEX_PASSWORD.test(resetPassword)}
                text={'비밀번호 변경'}
                type={'button'}
              />
            </SubmitContent>
          ) : (
            <SubmitContent>
              <SubTitle>이 페이지는 만료된 페이지입니다.</SubTitle>
              <SubTitle>로그인페이지에서 다시 설정 해주세요.</SubTitle>
              <div style={{ marginTop: '20px', width: '100%' }}>
                <Button onClick={closeBtnClick} btnBg={true} text={'확인'} type={'button'} />
              </div>
            </SubmitContent>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
}

export default withRouter(ResetPassword);
