import { makeVar } from '@apollo/client';
import { DateTime } from 'luxon';
import { AdminRole } from '__apollo__generated__/globalTypes';

// 비밀번호 변경, 회원탈퇴 모달 헨들러
export const popup = makeVar<boolean>(false);
// 회원탈퇴 모달 표시
export const deleteUserModal = makeVar<boolean>(false);
// 드롭다운 선택값
export const dropdownValue = makeVar<any>('');
// 캘린더 시작 날짜
export const startDate = makeVar<string>(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
// 캘린더 끝 날짜
export const endDate = makeVar<string>(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
// 시작 캘린더 모달 표시
export const showStartDate = makeVar<boolean>(false);
// 끝 캘린더 모달 표시
export const showEndDate = makeVar<boolean>(false);
// 회사 연결 해제 모달 표시
export const disconnectModal = makeVar<boolean>(false);

///--------------------------------------///
// activity

export const userIdVar = makeVar<number>(0);

export const adminRoleListVar = makeVar<AdminRole[] | null>([]);
