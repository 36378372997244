import { useHistory } from 'react-router-dom';

const usePageHistory = () => {
  const history = useHistory();
  const location = history.location;
  const pathName = history.location.pathname;
  const rootPathName = pathName.split('/')[2];
  const pageQuery = history.location.search;
  

  const currentPage = +pageQuery.split('&')[0].replace('?page=', '') || 1;
  const code = pageQuery.replace('?index=', '');

  return { history, pathName, pageQuery, currentPage, rootPathName, code, location };
};

export default usePageHistory;
