import { gql } from '@apollo/client';

export const SEND_MAIL_SUBSCRIPTION = gql`
  mutation Mut_Send_Mail_Subscription($input: CreateMailSubscriptionInfoInputDto!) {
    createMailSubscriptionInfo(input: $input) {
      error
      ok
    }
  }
`;
