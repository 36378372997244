import { gql } from '@apollo/client';

export const USE_LARCHIVE_FROM_OTHER_SERVICE_FOR_SNS = gql`
  mutation Mut_Use_Larchive_From_Other_Service_For_Sns($input: UseLarchiveFromOtherServiceForSnsInputDto!) {
    useLarchiveFromOtherServiceForSns(input: $input) {
      ok
      error {
        message
        code
      }
    }
  }
`;
