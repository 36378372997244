import Cookies from 'universal-cookie';
import { ApolloClient, createHttpLink, InMemoryCache, makeVar, ApolloLink, fromPromise } from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
// import { getMainDefinition } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';

import { onError } from '@apollo/link-error';
// import { onError } from '@apollo/client/link/error';

import mainAuth from 'auth';
import { FIREBASE_ID_TOKEN, LARCHIVE_TOKEN } from 'constant/user';
import sweetAlert from 'components/Alert/sweetAlert';
import CommonErrorDescription from 'components/Common/Error/CommonError';
// import { DateTime } from 'luxon';
// import sweetAlert from 'components/Alert/sweetAlert';

const cookies = new Cookies();
const abortController = new AbortController();
export const isLoggedInVar = makeVar(false);

// ?http링크
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}`,
  credentials: 'same-origin',
  fetchOptions: {
    signal: abortController.signal,
    cors: 'no-cors',
  },
});

// export let wsLink = createWebSocketLink();

// function createWebSocketLink() {
//   const token = cookies.get('firebase-id-token');
//   const larchiveToken = cookies.get('larchive-token');

//   const link = new WebSocketLink({
//     uri: `${process.env.REACT_APP_WS_ENDPOINT}`,
//     reconnect: token && larchiveToken ? true : false,
//     options: {
//       // reconnect: token && larchiveToken ? true : false,
//       connectionParams: async () => {
//         const token = await cookies.get('firebase-id-token');
//         const larchiveToken = await cookies.get('larchive-token');

//         return {
//           'firebase-id-token': token || '',
//           'larchive-token': larchiveToken || '',
//         };
//       },
//     },
//     webSocketImpl: WebSocket,
//   });

//   return link;
// }

//auth link
export let authLink = makeAuthLink();

//  todo : retry error test 해볼것
const retryLink = new RetryLink({
  attempts: {
    max: 5,
  },
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
});

// error Link
// todo : 지속적으로 확인해아함.
export const errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }): ApolloLink | any => {
  if (networkError) {
    console.error(response, 'response');

    // if (networkError && networkError.name === 'ServerError') {
    //   if ((networkError as ServerError).statusCode === 502) {
    //     sweetAlert({
    //       icon: 'info',
    //       title: '서버를 점검 중입니다.',
    //       description: (
    //         <div>
    //           <div>5~10분 후 다시 시도해주시기 바랍니다.</div>
    //           <div>이용에 불편을 드려 대단히 죄송합니다.</div>
    //           <div>문제가 30분 이상 지속된다면</div>
    //           <div>하단의 채널톡 또는 help@simplgis.com으로 문의바랍니다.</div>
    //           <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
    //         </div>
    //       ),
    //     });
    //   }
    // }
    return forward(operation);
  }

  // 현재 토큰 가져오기
  const previousToken = cookies.get('firebase-id-token');
  if (graphQLErrors) {
    console.error(graphQLErrors, 'graphql Error');
    if (graphQLErrors[0].message.includes('auth/id-token-expired')) {
      if (previousToken && previousToken !== 'undefined') {
        console.error('auth change start');
        return fromPromise(
          mainAuth()
            .authStateManage.getAccessToken(true)
            .then(res => {
              if (!res) {
                console.log(res, 'token undefined');
                cookies.remove('firebase-id-token', {
                  path: '/',
                });
                cookies.remove('larchive-token', {
                  path: '/',
                });
                isLoggedInVar(false);
                client.clearStore();
                mainAuth().authStateManage.authLogout();
                // window.location.reload();
                const oldHeader = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeader,
                    'firebase-id-token': '',
                  },
                });
                console.error('inside auth change failed');
                return;
              }

              return res;
            }),
        )
          .filter(value => {
            return Boolean(value);
          })
          .flatMap(accessToken => {
            cookies.set('firebase-id-token', accessToken, {
              maxAge: 31557600,
              sameSite: 'lax',
              path: '/',
            });
            const oldHeader = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeader,
                'firebase-id-token': accessToken,
              },
            });

            console.log('auth change done');
            return forward(operation);
          });
      }
    } else if (
      graphQLErrors[0].extensions?.code === 'FORBIDDEN' ||
      graphQLErrors[0].message.includes('auth/id-token-revoked') ||
      graphQLErrors[0].message.includes(
        '아이디토큰이 있는 사람은 라카이브 토큰도 있어야 서비스를 이용할 수 있습니다.',
      ) ||
      graphQLErrors[0].message.includes('로그인 유저만 사용 가능한 API입니다.') ||
      graphQLErrors[0].message.includes('기기정보가 유효하지 않습니다.') ||
      graphQLErrors[0].message.includes('라카이브 토큰에서 이메일을 가져오지 못해 구독 확인을 진행할 수 없습니다') ||
      graphQLErrors[0].message.includes('토큰이 만료되었습니다') ||
      graphQLErrors[0].message.includes('탈퇴') ||
      graphQLErrors[0].message.includes('라카이브 토큰만 있는 경우는 서비스를')
    ) {
      console.error(graphQLErrors, 'auth logout');

      isLoggedInVar(false);
      cookies.remove(FIREBASE_ID_TOKEN, {
        path: '/',
      });
      cookies.remove(LARCHIVE_TOKEN, {
        path: '/',
      });
      client.stop();
      client.clearStore();
      mainAuth().authStateManage.authLogout();

      setTimeout(() => {
        window.location.replace('/');
      }, 100);

      window.location.reload();

      return null;
    } else if (graphQLErrors[0].message.includes('Unexpected')) {
      return forward(operation);
    } else if (graphQLErrors[0].message.includes('status code 502')) {
      console.error(graphQLErrors, '502');
      sweetAlert({
        icon: 'info',
        title: '서버를 점검 중입니다.',
        description: (
          <div>
            <div>5~10분 후 다시 시도해주시기 바랍니다.</div>
            <div>이용에 불편을 드려 대단히 죄송합니다.</div>
          </div>
        ),
      });
      return forward(operation);
    } else if (graphQLErrors[0].message.includes('Failed to fetch')) {
      console.error(graphQLErrors, 'fail to fetch');
      return forward(operation);
    } else {
      console.error(graphQLErrors, 'uncaught error');
      const path = graphQLErrors?.[0]?.path?.[0] as string;
      const errorCode = graphQLErrors?.[0]?.extensions.code;
      const message = graphQLErrors?.[0]?.message;

      if (errorCode.includes('INTERNAL_SERVER_ERROR')) {
        if (
          path?.toLowerCase()?.includes('payment') ||
          path?.toLowerCase()?.includes('subscription') ||
          path?.toLowerCase()?.includes('refund')
        ) {
          sweetAlert({
            icon: 'error',
            title: '요청 중 에러가 발생했습니다.',
            description: (
              <CommonErrorDescription
                isError={true}
                informationElement={
                  <div>
                    <div>발생경로: {path}</div>
                    <div>발생원인: {message}</div>
                  </div>
                }
              />
            ),
          });
        }
      }
    }
  }
});

// ?split link
// export const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   // wsLink,
//   authLink.concat(httpLink).concat(retryLink),
// );
// ?cache정보
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            isLoggedInVar();
          },
        },
      },
    },
  },
});
// ?apolloclient 생성
export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink).concat(retryLink)]), //if above
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});

// idToken이 업데이트될 때마다 authLink를 업데이트하는 함수
export function makeAuthLink() {
  return setContext(async (_, { headers }) => {
    const token = await cookies.get('firebase-id-token');
    const larchiveToken = await cookies.get('larchive-token');

    // createWebSocketLink();

    return {
      headers: {
        ...headers,
        'firebase-id-token': token || '',
        'larchive-token': larchiveToken || '',
      },
    };
  });
}

const token = cookies.get('firebase-id-token');
const larchiveToken = cookies.get('larchive-token');

isLoggedInVar(Boolean(token) && Boolean(larchiveToken));
