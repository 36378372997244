import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import styled from 'styled-components';

interface SweetToastProps {
  icon?: SweetAlertIcon;
  title: string | HTMLElement;
  position?: SweetAlertPosition;
}

// const SweetToastTitle = styled.div`
//   font-size: 18px;
//   font-weight: 500;
//   color: #333333;
// `;

function sweetToast({ icon, title, position }: SweetToastProps) {
  const alert = withReactContent(Swal);
  const Toast = alert.mixin({
    width: 470,
    toast: true,
  });

  return Toast.fire({
    icon: icon || 'info',
    title: title,
    timer: 3000,
    position: position ?? 'center-right',
    showConfirmButton: false,
    background: '#ffffff',
    customClass: {
      title: 'text-center',
    },
  });
}

export default sweetToast;
