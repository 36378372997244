import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import gothamMedium from 'assets/font/GothamMedium/Gotham-Medium.otf';
import pretendardRegularWoff2 from 'assets/font/Pretendard/Pretendard-Regular.subset.woff2';
import pretendardRegularWoff from 'assets/font/Pretendard/Pretendard-Regular.subset.woff';
import pretendardMediumWoff2 from 'assets/font/Pretendard/Pretendard-Medium.subset.woff2';
import pretendardMediumWoff from 'assets/font/Pretendard/Pretendard-Medium.subset.woff';
import pretendardSemiBoldWoff2 from 'assets/font/Pretendard/Pretendard-Bold.subset.woff2';
import pretendardSemiBoldWoff from 'assets/font/Pretendard/Pretendard-Bold.subset.woff';
import pretendardBoldWoff2 from 'assets/font/Pretendard/Pretendard-Bold.subset.woff2';
import pretendardBoldWoff from 'assets/font/Pretendard/Pretendard-Bold.subset.woff';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: GothamMedium;
  font-weight: 500;
  font-display: swap;
  src:
  local("Gotham Medium"),
  url(${gothamMedium}) format('opentype')
}

  /* Pretendard */
  @font-face {
    font-family: Pretendard;
    font-weight: 400;
    font-display: swap;
    src:
    local("Pretendard Regular"),
    url(${pretendardRegularWoff2}) format('woff2'),
    url(${pretendardRegularWoff}) format('woff')
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 500;
    font-display: swap;
    src:
    local("Pretendard Medium"),
    url(${pretendardMediumWoff2}) format('woff2'),
    url(${pretendardMediumWoff}) format('woff')
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 600;
    font-display: swap;
    src:
    local("Pretendard SemiBold"),
    url(${pretendardSemiBoldWoff2}) format('woff2'),
    url(${pretendardSemiBoldWoff}) format('woff'),
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 700;
    font-display: swap;
    src:
    local("Pretendard Bold"),
    url(${pretendardBoldWoff2}) format('woff2'),
    url(${pretendardBoldWoff}) format('woff')
    ;
  }

  ${reset}

  * {
    box-sizing: border-box;


  }

  /* html {
    font-size: 62.5%;
  } */


  body
  {
    /* width: 100%;
    height: 100%; */
    /* box-sizing: border-box; */
    /* overflow-x: hidden; */
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color: ${({ theme }) => theme.colors.mainFontColor};
    letter-spacing: -0.02em;



  }
  input.highcharts-range-selector {
    color: red;
  }
  .highcharts-range-selector-buttons {
    .highcharts-label {
      text {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
  }
  b {
    font-weight: bold !important;
  }

  i {
    font-style: italic !important;
  }


  button {
    font-family: inherit;
    font-size: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.mainFontColor};
  }

  li {
    list-style: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input, textarea {
    font-family: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.mainFontColor};
  }
`;

export default GlobalStyle;

export const GlobalBtn = styled.button`
  padding: 14px 20px;
  background-color: ${({ theme }) => theme.colors.baseColor};
  border-radius: 20px;
  align-self: center;
  font-family: inherit;
  color: ${({ theme }) => theme.colors.backgroundColor};
  white-space: nowrap;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    background-color: rgba(20, 182, 114, 1);
  }
  &.mypage-delete {
    padding: 8px 13px;
  }
  &.board--write__button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 1.35714286;
  }
  &.board--submit__btn {
    width: 100%;
    height: 50px;
    font-size: 16px;
    margin-left: 10px;
  }
  &.reply--submit__btn {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
  &.comment--submit__btn {
    width: 60px;
    height: 35px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${props => props.theme.colors.baseColor};
    border: 1px solid ${props => props.theme.colors.baseColor};
    padding: 0;
  }
`;
