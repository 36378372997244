import { gql } from '@apollo/client';

export const GET_GPT_TREEMAP_DATA = gql`
  query Que_Get_Gpt_Treemap_Data($input: GetGptTreemapDataInputDto!) {
    getGptTreemapData(input: $input) {
      ok
      error
      result {
        parentList {
          id
          name
        }
        childList {
          name
          total
          negative
          positive
          parent
          nameEng
          categoryEng
        }
      }
    }
  }
`;

export const GET_ISSUE_TONE_LIST = gql`
  query Que_Get_Issue_Tone_List($input: GetIssueToneListInputDto!) {
    getIssueToneList(input: $input) {
      ok
      error
      result {
        date
        category
        tone_ratio
      }
    }
  }
`;

export const GET_RAWMATERIAL_TONE_LIST = gql`
  query Que_Get_Raw_Material_Tone_List($input: GetRawMaterialToneListInputDto!) {
    getRawMaterialToneList(input: $input) {
      ok
      error
      result {
        date
        category
        tone_ratio
      }
    }
  }
`;

export const GET_ISSUE_MONITOR_NEWS_LIST = gql`
  query Que_Get_Issue_Monitor_News_List($input: GetIssueMonitorNewsListInputDto!) {
    getIssueMonitorNewsList(input: $input) {
      ok
      error
      result {
        url
        category
        dates
        title
        keyword
        keywordEng
        category
        categoryKor
        sentiment
        translation
      }
    }
  }
`;
