import { gql } from '@apollo/client';

export const RESET_PASSWORD_IN_CUSTOM_PASSWORD_PAGE = gql`
  mutation Mut_Reset_Password_In_Custom_Password_Page($input: ResetPasswordInputDtoType!) {
    resetPasswordInCustomPasswordPage(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;
