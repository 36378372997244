// reg expression file

// email
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// nickname
export const REGEX_NICKNAME = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{2,10}$/;
// tel
export const REGEX_TEL = /^(\d{10,11}|\d{2,3}-\d{3,4}-\d{4})$/;
// password
export const REGEX_PASSWORD = /(?=.*\d{1,})(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+'";:/><[{}\]]{1,})(?=.*[a-zA-Z]{1,}).{8,}$/;

// number
export const REGEX_NUMBER = /[^0-9.]/g;
