// ❗️ uploadFile.ts 로 대체될 예정
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from 'lib/firebase/firebase';
import { DateTime } from 'luxon';
import uid from 'tiny-uid';

export default async function fileUpload(file: any, userId: number) {
  const exp = file?.name?.slice(-3);

  // luxon과 tiny-uid로 파일명 임의로 만들기
  const fileRef = ref(storage, `/user/${userId}/${DateTime.now().toFormat('yyyy/MM')}/${uid()}.${exp}`);
  // fileBlob
  await uploadBytes(fileRef, file, { contentDisposition: `attachment; filename="${file.name}"` });
  // downloadurl
  const url = await getDownloadURL(fileRef);
  return url;
}
