import usePageHistory from 'hooks/Common/usePageHistory';
import { useEffect } from 'react';

export default function useScrollToTop() {
  const { pathName, history } = usePageHistory();

  useEffect(() => {
    setTimeout(() => {
      document.documentElement?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 150);
  }, [pathName, history]);
}
